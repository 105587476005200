// Custom Theming for Angular Material
@use '@angular/material' as mat;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$lleego-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$lleego-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);
$lleego-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Define the theme map
$lleego: (
  color: (
    primary: $lleego-primary,
    accent: $lleego-accent,
    warn: $lleego-warn,
  ),
  typography: mat.m2-define-typography-config(),
  density: 0,
);

// Create the theme object (a Sass map containing all of the palettes).
$lleego-theme: mat.m2-define-light-theme($lleego);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($lleego-theme);

//calendar

.mat-calendar-body-cell-content.mat-calendar-body-selected {
  background-color: var(--color-primary) !important;
  box-shadow: none;
}

.mat-calendar-body-cell-content {
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: normal;
  font-size: var(--font-size-6);
  line-height: 14px;
  text-align: center;
  color: rgba(0, 0, 0, 0.867782);
  width: 25px !important;
  height: 25px !important;
  border-style: none !important;
}

.mat-calendar-body-today {
  color: var(--color-primary);
}

.mat-calendar-body-cell-content.mat-calendar-body-selected {
  color: white;
}

.mat-calendar-body-cell {
  width: 50px !important;
  div {
    pointer-events: none;
  }
}

.mat-calendar-body-label {
  padding: 0px !important;
  height: 0px;
}
.mat-calendar-table {
  .sub {
    background: rgba(174, 255, 201, 0.6);
  }
}

mat-calendar-header {
  .mat-calendar-header {
    height: 37px;
  }

  .mat-calendar-controls {
    position: relative;
    .mat-calendar-period-button.mat-button.mat-button-base {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      pointer-events: none;
    }
    .mat-calendar-previous-button {
      position: absolute;
      left: 19%;
      top: -2px;
    }
    .mat-calendar-next-button {
      position: absolute;
      right: 19%;
      top: -2px;
    }
    .mat-calendar-arrow {
      display: none;
    }
  }
}

//dropdown

.mat-calendar-header {
  padding: 0 !important;
}

.mat-menu-panel {
  min-width: none !important;
  max-width: none !important;
  width: 100% !important;
  border-radius: 20px !important;
  overflow: visible !important;
}

.mat-menu-content {
  display: flex;
  justify-content: space-between;
}

.mat-button-wrapper {
  font-family: Source Sans Pro, sans-serif;
  font-size: var(--font-size-4);
  line-height: 18px;
  color: #6f6f6f;
}

.mat-calendar-controls {
  margin: 0 !important;
}

.mat-calendar-body-label {
  opacity: 0;
}

// .mat-calendar-table-header {
//   display: none;
// }

.mat-menu-content img {
  padding-top: 17px !important;
}

// modals
mat-dialog-container {
  .header {
    display: flex;
    justify-content: flex-end;
    button.close {
      position: absolute;
      top: auto;
      right: auto;
      margin: 7px 8px;
      padding: 0;
      height: 0;
      z-index: 1;
      &::before {
        content: url('/assets/images/icons/close.svg');
        width: 17.5px;
        height: 17.5px;
      }
    }
  }

  .mat-mdc-dialog-surface {
    overflow-y: visible;
  }
}
