.isVeci {
  .layout-main-menu {
    &.hideMenu {
      display: none;
    }
  }
  .page-content-footer{
    padding-left: 0 !important;
  }
  .list-flights{
    top: 0 !important;
  }
  #header {
    background-color: #4B4C51;
    color: #fff;
    left: 0;
    margin-top: -5px !important;
    padding: 10px 0 5px;
    .container {
      padding-left: 35px;
    }
    .reorder {
      svg-icon {
        background: none;
      }
    }
    .title,
    .p-element
    {
      p,
      span {
        color: #fff !important;
      }
      svg path{
        fill: #fff;
      }
    }
    .p-autocomplete-panel,
    .calendar-dropdown,
    .calendar-info,
    .title.open,
    .dropdown
    {
      p,
      span:not(.selected-focus) {
        color: #6f6f6f !important;
      }
      .p-element
      {
        p,
        span:not(.selected-focus) {
          color: #6f6f6f !important;
        }
        svg path{
          fill: #6f6f6f;
        }
      }
    }
    .calendar-dropdown-box{
      top: 90px !important;
    }
    .title,
    .arrow {
      background: none !important;
      color: #fff !important;
      border: 0 none !important;
    }
    .barTemplate{
      background: none;
      color: #fff;
      box-shadow: none;
      .body-content,
      .advanced-filters
      {
        background: none !important;
        color: #fff;
      }
    }
  }
  #results .filters{
    background-color: #4B4C51;
    color: #fff !important;
    p,
    span,
    h2,
    .options h2,
    .list-checks app-fields-collection > button
    {
      color: #fff !important;
    }
    .options .tramo .journey.selected {
      background-color: var(--color-primary);
    }
    .options .tramo .journey.selected .index {
      color: var(--color-primary) !important;
      background-color: #fff !important;
    }
    .options .tramo .journey.selected .index path{
      fill: var(--color-primary) !important;
    }
    svg-icon path
    {
      fill: #fff !important;
    }
  }
  app-veci-pricing-header{
    width: 100%;
    height: 90px;
    text-align: center;
    background-color: #4B4C51;
    margin-top: -5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h1,
    h2{
      color: #fff;
      font-size: 30px;
      display: block;
      width: 100%;
      font-weight: 300;
      margin: 0;
      padding: 0;
    }
    h1{
      margin-top: 10px;
    }
    h2{
      font-size: 20px;
    }
  }
  .search-bar-skeleton .filters{
    background: none !important;
  }

  #results app-generic-result-filters .baggage,
  #results app-generic-result-filters .layovers
  {
    app-checkbox > label {
      padding-right: 0;
    }
    app-checkbox > label > p{
      background: #fff;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      margin-left: -5px;
      margin-top: -1px;
      border: 1px solid var(--color-primary) !important;
      color: var(--color-primary) !important;
      text-align: center;
    }
    app-checkbox > label > p::after{
      border: 0 none;
    }
    .empty app-checkbox > label > p{
      color: #fff !important;
      background: none;
      border: 1px solid #fff !important;
    }
  }
  .flights-box {
    border-bottom: 2px solid #67B500;
  }
}
