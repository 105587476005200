body,
p {
  color: var(--color-black);
  line-height: 1.188rem;
  font-family: var(--font-primary);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-4);
}

a {
  font-family: var(--font-primary);
  color: var(--color-grey-4);
  text-decoration: none;
  outline: 0;

  &:focus {
    outline: 0;
  }

  &:hover {
    color: var(--color-primary);
  }

  &.router-link-exact-active {
    color: var(--color-primary);
  }
}

h2 {
  font-family: var(--font-primary);
  line-height: 2.375rem;
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-1);
}

h3 {
  font-family: var(--font-primary);
  line-height: 2.063rem;
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-2);
}

h4 {
  font-family: var(--font-primary);
  line-height: 2.063rem;
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-2);
}

h5 {
  font-family: var(--font-primary);
  line-height: 1.563rem;
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-3);
}

strong {
  font-family: var(--font-primary);
  font-weight: var(--font-weight-semibold);
}

input {
  font-family: var(--font-primary);
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 32px;
  margin-bottom: 1rem;
  padding: 0 0.75rem;
  background-color: var(--color-grey-2);
  border: 0;
  border-radius: 5px;
  color: var(--color-grey-1);
  font-size: var(--font-size-4);

  &.white {
    background-color: var(--color-white);
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #f3f3f3;

  &.white {
    box-shadow: inset 0 0 20px 20px var(--color-white);
  }

  &.error {
    -webkit-background-clip: text;
    -webkit-text-fill-color: var(--color-text-error);
    transition: background-color 0.5s ease;
    box-shadow: inset 0 0 20px 20px rgb(255 209 206);
  }
}

button {
  font-family: var(--font-primary);
  min-height: 30px;
  padding: 0 1rem;
  border-radius: 20px;
  font-weight: var(--font-weight-semibold);
  border: 0;
}

.help {
  cursor: help;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
