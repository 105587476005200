.p-widget,
.p-widget * {
  box-sizing: border-box;
}

.p-helper-hidden {
  display: none !important;
}

.p-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.p-hidden-accessible input,
.p-hidden-accessible select {
  transform: scale(0);
}

.p-helper-reset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  line-height: 1.3;
  text-decoration: none;
  font-size: 100%;
  list-style: none;
}

.p-helper-clearfix::before,
.p-helper-clearfix::after {
  content: '';
  display: table;
}

.p-helper-clearfix::after {
  clear: both;
}

.p-helper-clearfix {
  zoom: 1;
}

.p-helper-zfix {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  filter: Alpha(Opacity=0);
}

.p-disabled {
  cursor: default !important;
}

.p-disabled a {
  cursor: default !important;
}

.p-icon {
  display: block;
  text-indent: -99999px;
  overflow: hidden;
  background-repeat: no-repeat;
}

.p-widget-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.p-resizable {
  position: relative;
}

.p-resizable-handle {
  position: absolute;
  font-size: 0.1px;
  display: block;
}

.p-resizable-disabled .p-resizable-handle,
.p-resizable-autohide .p-resizable-handle {
  display: none;
}

.p-resizable-n {
  cursor: n-resize;
  height: 7px;
  width: 100%;
  top: -5px;
  left: 0;
}

.p-resizable-s {
  cursor: s-resize;
  height: 7px;
  width: 100%;
  bottom: -5px;
  left: 0;
}

.p-resizable-e {
  cursor: e-resize;
  width: 7px;
  right: -5px;
  top: 0;
  height: 100%;
}

.p-resizable-w {
  cursor: w-resize;
  width: 7px;
  left: -5px;
  top: 0;
  height: 100%;
}

.p-resizable-se {
  cursor: se-resize;
  width: 12px;
  height: 12px;
  right: 1px;
  bottom: 1px;
}

.p-resizable-sw {
  cursor: sw-resize;
  width: 9px;
  height: 9px;
  left: -5px;
  bottom: -5px;
}

.p-resizable-nw {
  cursor: nw-resize;
  width: 9px;
  height: 9px;
  left: -5px;
  top: -5px;
}

.p-resizable-ne {
  cursor: ne-resize;
  width: 9px;
  height: 9px;
  right: -5px;
  top: -5px;
}

.p-shadow {
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
}

.p-unselectable-text {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.p-scrollbar-measure {
  width: 100px;
  height: 100px;
  overflow: scroll;
  position: absolute;
  top: -9999px;
}

.p-overflow-hidden {
  overflow: hidden;
}

.p-accordion {
  width: 100%;
}

.p-accordion .p-accordion-header {
  cursor: pointer;
  position: relative;
  margin-top: 1px;
  zoom: 1;
  z-index: 5;

  a {
    position: relative;
  }

  a::after {
    content: ' ';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }

  & > .p-accordion-header-link {
    .p-icon-wrapper {
      display: none !important;
    }
  }
}

.p-accordion .p-accordion-header a {
  display: block;
  padding: 0.5em;
}

.p-icon-wrapper {
  display: inline-flex;
  background-repeat: no-repeat;
  background-position-y: 6px;
  width: 15px;
  height: 15px;
  background-size: 7px 5px;
  order: 1;
}

.p-accordion .p-accordion-toggle-icon,
.p-accordion .p-accordion-header-text {
  vertical-align: middle;
}

.p-accordion .p-accordion-content {
  padding: 1em;
  border-top: 0;
  overflow: visible;
  zoom: 1;
}

.p-accordion .p-accordion-header.p-disabled,
.p-accordion .p-accordion-header.p-disabled a {
  cursor: default;
}

// .p-toggleable-content {
//   overflow: hidden;
// }

.p-rtl .p-accordion .p-accordion-header a {
  padding: 0.5em 2em 0.5em 0.5em;
}

.p-rtl .p-accordion .p-accordion-toggle-icon {
  left: initial;
  right: 0.5em;
}

.p-rtl .p-accordion .p-accordion-toggle-icon.pi-caret-right:before {
  content: '\e904';
}

.p-widget,
.p-widget * {
  box-sizing: border-box;
}

.p-helper-hidden {
  display: none !important;
}

.p-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.p-hidden-accessible input,
.p-hidden-accessible select {
  transform: scale(0);
}

.p-helper-reset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  line-height: 1.3;
  text-decoration: none;
  font-size: 100%;
  list-style: none;
}

.p-helper-clearfix::before,
.p-helper-clearfix::after {
  content: '';
  display: table;
}

.p-helper-clearfix::after {
  clear: both;
}

.p-helper-clearfix {
  zoom: 1;
}

.p-helper-zfix {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  filter: Alpha(Opacity=0);
}

.p-disabled {
  cursor: default !important;
}

.p-disabled,
.p-disabled * {
  cursor: default !important;
}

.p-disabled a {
  cursor: default !important;
}

.p-icon {
  display: block;
  text-indent: -99999px;
  overflow: hidden;
  background-repeat: no-repeat;
}

.p-widget-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.p-resizable {
  position: relative;
}

.p-resizable-handle {
  position: absolute;
  font-size: 0.1px;
  display: block;
}

.p-resizable-disabled .p-resizable-handle,
.p-resizable-autohide .p-resizable-handle {
  display: none;
}

.p-resizable-n {
  cursor: n-resize;
  height: 7px;
  width: 100%;
  top: -5px;
  left: 0;
}

.p-resizable-s {
  cursor: s-resize;
  height: 7px;
  width: 100%;
  bottom: -5px;
  left: 0;
}

.p-resizable-e {
  cursor: e-resize;
  width: 7px;
  right: -5px;
  top: 0;
  height: 100%;
}

.p-resizable-w {
  cursor: w-resize;
  width: 7px;
  left: -5px;
  top: 0;
  height: 100%;
}

.p-resizable-se {
  cursor: se-resize;
  width: 12px;
  height: 12px;
  right: 1px;
  bottom: 1px;
}

.p-resizable-sw {
  cursor: sw-resize;
  width: 9px;
  height: 9px;
  left: -5px;
  bottom: -5px;
}

.p-resizable-nw {
  cursor: nw-resize;
  width: 9px;
  height: 9px;
  left: -5px;
  top: -5px;
}

.p-resizable-ne {
  cursor: ne-resize;
  width: 9px;
  height: 9px;
  right: -5px;
  top: -5px;
}

.p-shadow {
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
}

.p-unselectable-text {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.p-scrollbar-measure {
  width: 100px;
  height: 100px;
  overflow: scroll;
  position: absolute;
  top: -9999px;
}

.p-overflow-hidden {
  overflow: hidden;
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #898989;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #898989;
  opacity: 1;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #898989;
  opacity: 1;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #898989;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #898989;
}

//.p-placeholder {
//  color: #898989;
//}

.p-blockui {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.p-blockui-document {
  position: fixed;
}

.p-autocomplete {
  width: auto;
  zoom: 1;
  cursor: pointer;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  position: relative;
  display: inline-block;
}

.p-autocomplete .p-autocomplete-dropdown {
  height: 100%;
  width: 2em;
  margin-right: 0;
  vertical-align: top;
}

.p-autocomplete .p-autocomplete-input {
  padding-right: 1.5em;
}

.p-autocomplete-loader {
  position: absolute;
  right: 0.25em;
  top: 50%;
  margin-top: -0.5em;
}

.p-autocomplete-query {
  font-weight: var(--font-weight-semibold);
}

.p-autocomplete .p-autocomplete-panel {
  min-width: 100%;
}

.p-autocomplete-panel {
  position: absolute;
  overflow: auto;
}

.p-autocomplete-panel .p-autocomplete-list {
  padding: 0.4em;
  border: 0 none;
}

.p-autocomplete-panel .p-autocomplete-item {
  border: 0 none;
  cursor: pointer;
  font-weight: normal;
  margin: 1px 0;
  padding: 0.186em 0.313em;
  text-align: left;
}

.p-autocomplete .p-button-icon-only,
.p-autocomplete .p-button-icon-only:enabled:hover,
.p-autocomplete .p-button-icon-only:enabled:focus,
.p-autocomplete .p-button-icon-only:enabled:active {
  border-left: 0 none;
}

/* Multiple Selection */
.p-autocomplete-multiple-container {
  display: inline-block;
  vertical-align: middle;
}

.p-autocomplete-multiple-container.p-inputtext {
  clear: left;
  cursor: text;
  list-style-type: none;
  margin: 0;
  overflow: hidden;
  padding: 0 1.5em 0 0.25em;
}

.p-autocomplete-token {
  cursor: default;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  padding: 0.125em 0.5em;
  white-space: nowrap;
  position: relative;
  margin-right: 0.125em;
  border: 0 none;
  font-size: 0.9em;
}

.p-autocomplete-token-label {
  display: block;
  margin-right: 2em;
}

.p-autocomplete-token-icon {
  margin-top: -0.5em;
  position: absolute;
  right: 0.2em;
  top: 50%;
  cursor: pointer;
}

.p-autocomplete-input-token {
  display: inline-block;
  vertical-align: middle;
  list-style-type: none;
  margin: 0 0 0 0.125em;
  padding: 0.25em 0.25em 0.25em 0;
}

.p-autocomplete-input-token input {
  border: 0 none;
  width: 10em;
  outline: medium none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

.p-autocomplete-dd .p-autocomplete-loader {
  right: 2.25em;
}

.p-autocomplete-dd input.p-corner-all,
.p-autocomplete-dd .p-autocomplete-multiple-container.p-corner-all {
  -moz-border-radius-topright: 0px;
  -webkit-border-top-right-radius: 0px;
  border-top-right-radius: 0px;
  -moz-border-radius-bottomright: 0px;
  -webkit-border-bottom-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.p-autocomplete-dd .p-autocomplete-dropdown.p-corner-all {
  -moz-border-radius-topleft: 0px;
  -webkit-border-top-left-radius: 0px;
  border-top-left-radius: 0px;
  -moz-border-radius-bottomleft: 0px;
  -webkit-border-bottom-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

/** AutoComplete **/
.p-fluid p-autocomplete,
.p-fluid .p-autocomplete,
.p-fluid .p-autocomplete-input {
  width: 100%;
}

.p-fluid .p-autocomplete.p-autocomplete-dd .p-autocomplete-input,
.p-fluid .p-autocomplete.p-autocomplete-dd .p-autocomplete-multiple-container {
  width: calc(100% - 2em);
}

.p-fluid .p-autocomplete .p-autocomplete-dropdown.p-button {
  width: 2em;
}

/* Button */
.p-button {
  display: inline-block;
  position: relative;
  padding: 0;
  text-decoration: none !important;
  cursor: pointer;
  text-align: center;
  zoom: 1;
  overflow: visible; /* the overflow property removes extra width in IE */
}

p-button {
  display: inline-block;
}

/*button text element */
.p-button .p-button-text {
  display: block;
  line-height: normal;
}

.p-button-text-only .p-button-text {
  padding: 0.25em 1em;
}

.p-button-icon-only .p-button-text,
.p-button-text-empty .p-button-text {
  padding: 0.25em;
  text-indent: -9999999px;
}

.p-button-text-icon-left .p-button-text {
  padding: 0.25em 1em 0.25em 2.1em;
}

.p-button-text-icon-right .p-button-text {
  padding: 0.25em 2.1em 0.25em 1em;
}

/*button icon element(s) */
.p-button-icon-only .p-button-icon-left,
.p-button-text-icon-left .p-button-icon-left,
.p-button-text-icon-right .p-button-icon-right {
  position: absolute;
  top: 50%;
  margin-top: -0.5em;
  height: 1em;
}

.p-button-icon-only .p-button-icon-left {
  top: 50%;
  left: 50%;
  margin-top: -0.5em;
  margin-left: -0.5em;
  width: 1em;
  height: 1em;
}

.p-button-icon-left {
  left: 0.5em;
}

.p-button-icon-right {
  right: 0.5em;
}

/*button sets*/
.p-buttonset .p-button {
  margin-left: 0;
  margin-right: 0;
}

/* workarounds */
button.p-button::-moz-focus-inner {
  border: 0;
  padding: 0; /* reset extra padding in Firefox */
}

/** Fluid **/
.p-fluid .p-button {
  width: 100%;
}

.p-fluid .p-button-text-icon-left .p-button-text,
.p-fluid .p-button-text-icon-right .p-button-text {
  padding-left: 1em;
  padding-right: 1em;
}

/** ButtonSet **/
.p-fluid .p-buttonset {
  width: 100%;
}

.p-fluid .p-buttonset.p-buttonset-1 .p-button {
  width: 100%;
}

.p-fluid .p-buttonset.p-buttonset-2 .p-button {
  width: 50%;
}

.p-fluid .p-buttonset.p-buttonset-3 .p-button {
  width: 33.3%;
}

.p-fluid .p-buttonset.p-buttonset-4 .p-button {
  width: 25%;
}

.p-fluid .p-buttonset.p-buttonset-5 .p-button {
  width: 20%;
}

.p-fluid .p-buttonset.p-buttonset-6 .p-button {
  width: 16.6%;
}

@media (max-width: 640px) {
  .p-fluid .p-buttonset.p-buttonset-1 .p-button,
  .p-fluid .p-buttonset.p-buttonset-2 .p-button,
  .p-fluid .p-buttonset.p-buttonset-3 .p-button,
  .p-fluid .p-buttonset.p-buttonset-4 .p-button,
  .p-fluid .p-buttonset.p-buttonset-5 .p-button,
  .p-fluid .p-buttonset.p-buttonset-6 .p-button {
    width: 100%;
  }
}

/** Breadcrumb **/
.p-breadcrumb {
  margin: 0;
  padding: 0;
  padding: 0.5em;
}

.p-breadcrumb ul {
  margin: 0;
  padding: 0;
}

.p-breadcrumb ul li {
  display: inline-block;
  margin: 0 0.25em;
}

.p-breadcrumb-chevron,
.p-breadcrumb-home {
  vertical-align: middle;
}

.p-breadcrumb ul li .p-menuitem-link {
  text-decoration: none;
}

.p-breadcrumb .p-menuitem-icon {
  margin-right: 0.25em;
  vertical-align: middle;
}

.p-breadcrumb .p-menuitem-text {
  vertical-align: middle;
}

.p-calendar {
  position: relative;
  display: inline-block;
}

.p-calendar .p-calendar-button {
  height: 100%;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  width: 2em;
  border-left: 0 none;
}

.p-calendar .p-calendar-button:enabled:hover,
.p-calendar .p-calendar-button:focus {
  border-left: 0 none;
}

.p-calendar .p-datepicker {
  min-width: 100%;
}

/* Fluid */
.p-fluid .p-calendar {
  width: 100%;
}

.p-fluid .p-calendar-button {
  width: 2em;
}

.p-fluid .p-datepicker-buttonbar button {
  width: auto;
}

.p-fluid .p-calendar.p-calendar-w-btn .p-inputtext {
  width: calc(100% - 2em);
}

/* Datepicker */
.p-datepicker {
  width: auto;
  padding: 0.2em;
  position: absolute;
}

.p-datepicker.p-datepicker-inline {
  display: inline-block;
  position: static;
  width: 100% !important;
}

.p-datepicker .p-datepicker-group {
  border-left-width: 0;
  border-right-width: 0;
  border-top-width: 0;
  border-bottom-width: 0;
}

/* Header */
.p-datepicker .p-datepicker-header {
  position: relative;
  padding: 0.5em 0;
}

.p-datepicker .p-datepicker-prev,
.p-datepicker .p-datepicker-next {
  position: absolute;
  top: 0.5em;
  width: 1.8em;
  height: 1.8em;
  min-height: 0;
  padding: 0;
}

.p-datepicker .p-datepicker-prev {
  left: 0.125em;
}

.p-datepicker .p-datepicker-next {
  right: 0.125em;
}

.p-datepicker .p-datepicker-prev span,
.p-datepicker .p-datepicker-next span {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -0.5em;
  margin-left: -0.5em;
}

.p-datepicker .p-datepicker-title {
  margin: 0 auto;
  line-height: 1.8em;
  text-align: center;
}

.p-datepicker .p-datepicker-title select {
  font-size: var(--font-size-12);
  margin: 0.125em 0;
  vertical-align: middle;
}

.p-datepicker select.p-datepicker-month {
  margin-right: 0.25em;
}

.p-datepicker span.p-datepicker-year {
  margin-left: 0.25em;
}

/* Multiple Month DatePicker */
.p-datepicker-multiple-month .p-datepicker-group {
  display: table-cell;
  border-left-width: 0;
  border-top-width: 0;
  border-bottom-width: 0;
  border-right-width: 1px;
}

/* DatePicker Table */
.p-datepicker table {
  width: 100%;
  font-size: 0.9em;
  border-collapse: collapse;
  margin: 0 0 0.4em;
}

.p-datepicker th {
  padding: 0.5em;
  text-align: center;
  font-weight: var(--font-weight-semibold);
  border: 0;
}

.p-datepicker td {
  border: 0;
  padding: 0;
}

.p-datepicker td > span,
.p-datepicker td > a {
  display: block;
  padding: 0.5em;
  text-decoration: none;
  border: 0 none;
  text-align: center;
}

.p-datepicker .p-datepicker-buttonbar {
  border-left: 0 none;
  border-right: 0 none;
  border-bottom: 0 none;
  padding: 0.5em;
}

.p-datepicker .p-datepicker-buttonbar > .p-g > div:last-child {
  text-align: right;
}

.p-datepicker .p-datepicker-buttonbar > .p-g > div {
  padding: 0;
}

.p-calendar.p-calendar-w-btn input {
  -moz-border-radius-topright: 0px;
  -webkit-border-top-right-radius: 0px;
  -khtml-border-top-right-radius: 0px;
  border-top-right-radius: 0px;
  -moz-border-radius-bottomright: 0px;
  -webkit-border-bottom-right-radius: 0px;
  -khtml-border-bottom-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

/* Month Picker */
.p-monthpicker .p-monthpicker-month {
  width: 33.3%;
  display: inline-block;
  text-align: center;
  padding: 0.5em;
  cursor: pointer;
}

.p-datepicker-monthpicker select.p-datepicker-year {
  width: auto;
}

/* Time Picker */
.p-timepicker {
  text-align: center;
  padding: 0.5em 0;
}

.p-timepicker > div {
  display: inline-block;
  margin-left: 0.5em;
  min-width: 1.5em;
}

.p-timepicker > .p-minute-picker,
.p-timepicker > .p-second-picker {
  margin-left: 0;
}

.p-timepicker > .p-separator {
  margin-left: 0px;
  min-width: 0.75em;
}

.p-timepicker > .p-separator a {
  visibility: hidden;
}

.p-timepicker > div a {
  display: block;
  opacity: 0.7;
  filter: Alpha(Opacity=70);
  cursor: pointer;
}

.p-timepicker > div a:last-child {
  margin-top: 0.3em;
}

.p-timepicker > div a:hover {
  display: block;
  opacity: 1;
  filter: Alpha(Opacity=100);
}

input[type='text']::-ms-clear {
  display: none;
}

/* Touch UI */
.p-datepicker-touch-ui,
.p-calendar .p-datepicker-touch-ui {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 80vw;
}

.p-datepicker-touch-ui.p-datepicker th {
  padding: 2em 0;
}

.p-datepicker-touch-ui.p-datepicker td {
  padding: 0;
}

.p-datepicker-touch-ui.p-datepicker td > span,
.p-datepicker-touch-ui.p-datepicker td > a {
  padding: 2em 0;
}

.p-datepicker-touch-ui .p-timepicker {
  padding: 1em 0;
}

.p-datepicker-touch-ui .p-timepicker > div a {
  font-size: 2em;
}

.p-datepicker-mask {
  position: fixed;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 40em) {
  .p-datepicker-multiple-month {
    width: 17em;
    overflow: auto;
  }

  .p-datepicker-touch-ui.p-datepicker th {
    padding: 1em 0;
  }

  .p-datepicker-touch-ui.p-datepicker td span,
  .p-datepicker-touch-ui.p-datepicker td a {
    padding: 1em 0;
  }
}

.p-card-header img {
  width: 100%;
}

.p-card-body {
  padding: 1em;
}

.p-card-title {
  font-size: 1.5em;
  font-weight: var(--font-weight-semibold);
  margin-bottom: 0.5em;
}

.p-card-subtitle {
  opacity: 0.7;
  margin-bottom: 0.5em;
  margin-top: -0.25em;
  font-weight: var(--font-weight-semibold);
}

.p-card-footer {
  padding-top: 1em;
}

.p-carousel {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.p-carousel-content {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow: auto;
  padding: 0 0.5em;
}

.p-carousel-prev,
.p-carousel-next {
  align-self: center;
  text-align: center;
  flex-grow: 0;
  flex-shrink: 0;
  width: 2.5em;
  height: 2.5em;
  position: relative;
}

.p-carousel-prev span,
.p-carousel-next span {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-carousel-container {
  display: flex;
  flex-direction: row;
  padding: 0 0.1em;
}

.p-carousel-header,
.p-carousel-footer {
  padding: 0.5em;
  z-index: 1;
}

.p-carousel-items-content {
  overflow: hidden;
  width: 100%;
}

.p-carousel-items-container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
}

.p-carousel-items-container .p-carousel-item {
  width: 100%;
  box-sizing: border-box;
  overflow: auto;
}

.p-carousel-dots-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0.5em;
}

/* Vertical */
.p-carousel-vertical .p-carousel-container {
  flex-direction: column;
  width: 100%;
}

.p-carousel-vertical .p-carousel-items-container {
  flex-direction: column;
  height: 100%;
}

.p-carousel-vertical .p-carousel-dots-container {
  margin: 0.75em 0;
}

.p-checkbox {
  display: inline-block;
  cursor: pointer;
  vertical-align: middle;
  margin-right: 0.25em;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.p-checkbox .p-checkbox-box {
  width: 1.125em;
  height: 1.125em;
  line-height: 1.125em;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  text-align: center;
}

.p-checkbox .p-checkbox-icon {
  display: none;
}

.p-checkbox-label {
  vertical-align: middle;
}

.p-checkbox-readonly {
  cursor: default;
}

.p-chips > ul.p-inputtext {
  clear: left;
  cursor: text;
  list-style-type: none;
  margin: 0;
  overflow: hidden;
  padding: 0 0.25em;
}

.p-chips-token {
  cursor: default;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  padding: 0.125em 0.5em;
  white-space: nowrap;
  position: relative;
  margin-right: 0.125em;
  border: 0 none;
  font-size: 0.9em;
}

.p-chips-token .p-chips-token-label {
  display: block;
  margin-right: 2em;
}

.p-chips > .p-disabled .p-chips-token-label {
  margin-right: 0;
}

.p-chips-token .p-chips-token-icon {
  margin-top: -0.5em;
  position: absolute;
  right: 0.2em;
  top: 50%;
  cursor: pointer;
}

.p-chips-input-token {
  display: inline-block;
  vertical-align: middle;
  list-style-type: none;
  margin: 0 0 0 0.125em;
  padding: 0.25em 0.25em 0.25em 0;
}

.p-chips-input-token input {
  border: 0 none;
  width: 10em;
  outline: medium none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

.p-colorpicker {
  display: inline-block;
}

.p-colorpicker-dragging {
  cursor: pointer;
}

.p-colorpicker-overlay {
  position: relative;
}

.p-colorpicker-panel {
  position: relative;
  width: 193px;
  height: 166px;
  background-color: #323232;
  border-color: #191919;
}

.p-colorpicker-overlay-panel {
  position: absolute;
}

.p-colorpicker-preview {
  width: 2em;
  cursor: pointer;
}

.p-colorpicker-panel .p-colorpicker-content {
  position: relative;
}

.p-colorpicker-panel .p-colorpicker-color-selector {
  width: 150px;
  height: 150px;
  top: 8px;
  left: 8px;
  position: absolute;
}

.p-colorpicker-panel .p-colorpicker-color {
  width: 150px;
  height: 150px;
}

.p-colorpicker-panel .p-colorpicker-color-handle {
  position: absolute;
  top: 0px;
  left: 150px;
  border-radius: 100%;
  width: 10px;
  height: 10px;
  border: 1px solid #ffffff;
  margin: -5px 0 0 -5px;
  cursor: pointer;
}

.p-colorpicker-panel .p-colorpicker-hue {
  width: 17px;
  height: 150px;
  top: 8px;
  left: 167px;
  position: absolute;
  opacity: 0.85;
}

.p-colorpicker-panel .p-colorpicker-hue-handle {
  position: absolute;
  top: 150px;
  left: 0px;
  width: 21px;
  margin-left: -2px;
  margin-top: -5px;
  height: 10px;
  border: 2px solid #ffffff;
  opacity: 0.85;
  cursor: pointer;
}

.p-colorpicker-panel.p-disabled .p-colorpicker-hue-handle,
.p-colorpicker-panel.p-disabled .p-colorpicker-color-handle {
  opacity: 0.5;
}

.p-dataview {
  position: relative;
}

.p-dataview .p-paginator {
  text-align: center;
}

.p-dataview-column {
  padding: 0.25em;
}

.p-dataview-content-empty {
  padding: 0.25em 0.625em;
}

.p-dataview .p-dataview-header,
.p-dataview .p-dataview-footer {
  padding: 0.5em 0.75em;
}

.p-dataview .p-dataview-header {
  border-bottom: 0 none;
}

.p-dataview .p-dataview-footer {
  border-top: 0 none;
}

.p-dataview .p-paginator-top {
  border-bottom: 0 none;
}

.p-dataview .p-paginator-bottom {
  border-top: 0 none;
}

.p-dataview.p-dataview-list > .p-dataview-content > div.p-g > div {
  width: 100%;
}

.p-dataview .p-dataview-emptymessage {
  padding: 0.5em 0.75em;
}

/* Loader */
.p-dataview-loading {
  position: absolute;
  width: 100%;
  height: 100%;
  filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=10)';
  opacity: 0.1;
  z-index: 1;
}

.p-dataview-loading-content {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 2;
  margin-top: -1em;
  margin-left: -1em;
}

.p-dataview-loading-icon {
  font-size: 2em;
}

.p-contextmenu {
  width: 12.5em;
  padding: 0.25em;
  position: absolute;
  display: none;
}

.p-contextmenu .p-menu-separator {
  border-width: 1px 0 0 0;
}

.p-contextmenu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.p-contextmenu .p-submenu-list {
  display: none;
  position: absolute;
  width: 12.5em;
  padding: 0.25em;
}

.p-contextmenu .p-menuitem-link {
  padding: 0.25em;
  display: block;
  position: relative;
}

.p-contextmenu .p-menuitem-icon {
  margin-right: 0.25em;
  vertical-align: middle;
}

.p-contextmenu .p-menuitem-text {
  vertical-align: middle;
}

.p-contextmenu .p-menuitem {
  position: relative;
}

.p-contextmenu .p-menuitem-link .p-submenu-icon {
  position: absolute;
  margin-top: -0.5em;
  right: 0;
  top: 50%;
}

.p-contextmenu .p-menuitem-active > .p-submenu > .p-submenu-list {
  display: block !important;
}

.p-dialog {
  position: fixed;
  padding: 0;
}

.p-dynamicdialog {
  top: 50%;
  left: 50%;
}

.p-dialog .p-dialog-titlebar {
  padding: 0.5em 0.75em;
  position: relative;
  border: 0;
}

.p-dialog .p-dialog-content {
  position: relative;
  border: 0;
  padding: 0.5em 0.75em;
  background: none;
  zoom: 1;
}

.p-dialog-resizable .p-dialog-content {
  overflow: auto;
}

.p-dialog .p-resizable-se {
  width: 14px;
  height: 14px;
  right: 3px;
  bottom: 3px;
}

.p-draggable .p-dialog-titlebar {
  cursor: move;
}

.p-dialog .p-dialog-titlebar-icon {
  text-decoration: none;
  float: right;
  padding: 0.125em;
  cursor: pointer;
  border: 1px solid transparent;
}

.p-dialog .p-dialog-titlebar-icon span {
  display: block;
  margin: 0;
}

.p-dialog-footer {
  padding: 1em;
  border-width: 1px 0 0 0;
  text-align: right;
}

.p-dialog-mask {
  position: fixed;
  width: 100%;
  height: 100%;
}

.p-dialog-maximized {
  -webkit-transition: left 0.3s, top 0.3s, width 0.3s, height 0.3s;
  transition: left 0.3s, top 0.3s, width 0.3s, height 0.3s;
}

/* ConfirmDialog */
.p-confirmdialog {
  width: 30em;
  top: 50%;
  left: 50%;
}

.p-confirmdialog.p-dialog .p-dialog-content {
  padding: 1em 2em;
}

.p-confirmdialog .p-dialog-content .p-confirmdialog-icon {
  font-size: 1.5em;
  margin-right: 0.5em;
  position: relative;
  top: 0.2em;
}

.p-dialog-footer .p-button {
  margin-right: 0.25em;
}

/* Fluid */
.p-fluid .p-dialog-footer .p-button {
  width: auto;
}

/* RTL */
.p-rtl .p-dialog .p-dialog-titlebar-close {
  float: left;
}

.p-rtl .p-dialog .p-dialog-buttonpane button {
  text-align: right;
}

@media screen and (max-width: 40em) {
  .p-confirmdialog {
    width: 90%;
  }
}

.p-dropdown {
  display: inline-block;
  position: relative;
  cursor: pointer;
  vertical-align: middle;
  // min-width: 12.5em;
}

.p-dropdown .p-dropdown-hidden-select {
  bottom: 0;
  clip: auto;
}

.p-dropdown .p-dropdown-hidden-select select {
  -webkit-transform: none;
  transform: none;
  height: 1px;
  position: absolute;
  top: 0;
  clip: rect(0px, 0px, 0px, 0px);
  pointer-events: none;
}

.p-dropdown .p-dropdown-clear-icon {
  position: absolute;
  right: 2em;
  top: 50%;
  font-size: var(--font-size-12);
  height: 1em;
  margin-top: -0.5em;
}

.p-dropdown .p-dropdown-trigger {
  border-right: none;
  border-top: none;
  border-bottom: none;
  cursor: pointer;
  width: 1.5em;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  padding: 0 0.25em;
}

.p-dropdown .p-dropdown-trigger .p-dropdown-trigger-icon {
  top: 50%;
  left: 50%;
  margin-top: -0.3em;
  margin-left: -0.5em;
  position: absolute;
}

.p-dropdown .p-dropdown-label {
  display: block;
  border: none;
  white-space: nowrap;
  overflow: hidden;
  font-weight: normal;
  width: 100%;
  padding-right: 1.6em;
}

.p-dropdown-item-empty,
.p-dropdown-label-empty {
  text-indent: -9999px;
  overflow: hidden;
}

.p-dropdown.p-disabled .p-dropdown-trigger,
.p-dropdown.p-disabled .p-dropdown-label {
  cursor: default;
}

.p-dropdown label.p-dropdown-label {
  cursor: pointer;
}

.p-dropdown input.p-dropdown-label {
  cursor: default;
}

.p-dropdown .p-dropdown-panel {
  min-width: 100%;
}

.p-dropdown-panel {
  position: absolute;
  height: auto;
}

.p-dropdown-panel .p-dropdown-items-wrapper {
  overflow: auto;
}

.p-dropdown-panel .p-dropdown-item {
  font-weight: normal;
  border: 0 none;
  cursor: pointer;
  margin: 1px 0;
  padding: 0.125em 0.25em;
  text-align: left;
}

.p-dropdown-panel .p-dropdown-empty-message {
  padding: 0.5em;
}

.p-dropdown-panel .p-dropdown-item-group {
  font-weight: var(--font-weight-semibold);
  cursor: default;
}

.p-dropdown-panel .p-dropdown-items {
  padding: 0.4em;
  border: 0 none;
}

.p-dropdown-panel .p-dropdown-filter {
  width: 100%;
  box-sizing: border-box;
  padding-right: 1.5em;
}

.p-dropdown-panel .p-dropdown-filter-container {
  position: relative;
  margin: 0;
  padding: 0.4em;
  display: inline-block;
  width: 100%;
}

.p-dropdown-panel .p-dropdown-filter-container .p-dropdown-filter-icon {
  position: absolute;
  top: 0.8em;
  right: 1em;
}

/** Dropdown **/
.p-fluid .p-dropdown {
  width: 100%;
  min-width: 100%;
}

.p-fieldset,
.p-fieldset .p-fieldset-legend {
  padding: 0.5em 1em;
}

.p-fieldset-toggleable .p-fieldset-legend {
  padding: 0;
}

.p-fieldset-toggleable .p-fieldset-legend a {
  padding: 0.5em 1em;
  cursor: pointer;
  white-space: nowrap;
  display: block;
}

.p-fieldset .p-fieldset-toggler {
  margin-right: 0.1em;
  display: inline-block;
  vertical-align: middle;
}

.p-fieldset .p-fieldset-legend-text {
  vertical-align: middle;
}

.p-fieldset .p-fieldset-content-wrapper-overflown {
  overflow: hidden;
}

/*
 * FileUpload
 */
.p-fileupload-buttonbar .p-fileupload-choose.p-disabled input {
  cursor: default;
}

.p-fileupload-buttonbar {
  padding: 0.5em;
  border-bottom: 0 none;
}

.p-fileupload-buttonbar .p-button {
  vertical-align: middle;
  margin-right: 0.25em;
}

.p-fileupload-content {
  padding: 1em;
  position: relative;
  transition: border-color 0.3s;
}

.p-fileupload-content.p-fileupload-highlight {
  border-color: #156090;
}

.p-fileupload-files img {
  border: none;
}

.p-fileupload-files {
  display: table;
}

.p-fileupload-row {
  display: table-row;
}

.p-fileupload-row > div {
  display: table-cell;
  padding: 0.5em 1em;
  vertical-align: middle;
}

.p-fileupload-content .p-progressbar {
  width: 100%;
  position: absolute;
  top: 1px;
  left: 0;
  height: 0.25em;
  border: 0 none;
}

.p-fileupload-content .p-progressbar-value {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  border: 0 none;
}

/* Simple */
.p-fileupload-choose {
  position: relative;
  overflow: hidden;
}

.p-fileupload-choose input[type='file'] {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  direction: ltr;
  cursor: pointer;
  z-index: 1;
}

.p-fileupload-choose.p-fileupload-choose-selected input[type='file'] {
  display: none;
}

/* p-fluid */
.p-fluid .p-fileupload .p-button {
  width: auto;
}

.p-fluid .p-fileupload-content .p-button-icon-only {
  width: 2em;
}

@media (max-width: 40em) {
  .p-fileupload-buttonbar .p-button {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-bottom: 0.25em;
  }

  .p-fileupload-buttonbar .p-button:last-child {
    margin-bottom: 0;
  }

  .p-fileupload-row > div {
    display: block;
  }
}

.p-fluid .fc .p-button {
  width: auto;
}

.p-galleria {
  overflow: hidden;
  visibility: hidden;
  position: relative;
}

.p-galleria .p-galleria-panel-wrapper {
  position: relative;
  padding: 0;
  margin: 0;
}

.p-galleria .p-galleria-panel {
  filter: inherit;
  position: absolute;
  top: 0;
  left: 0;
  list-style-type: none;
}

.p-galleria .p-galleria-filmstrip-wrapper {
  overflow: hidden;
  margin: 0.25em auto;
  position: relative;
}

.p-galleria .p-galleria-filmstrip {
  list-style: none outside none;
  margin: 0;
  padding: 0;
  width: 2340px;
  position: absolute;
  top: 0;
  left: 0;
}

.p-galleria .p-galleria-frame {
  float: left;
  margin-right: 5px;
  opacity: 0.3;
  cursor: pointer;
}

.p-galleria .p-galleria-frame-active {
  opacity: 1;
}

.p-galleria .p-galleria-frame-content {
  overflow: hidden;
}

.p-galleria .p-galleria-nav-next,
.p-galleria .p-galleria-nav-prev {
  cursor: pointer;
  position: absolute;
  z-index: 1;
}

.p-galleria .p-galleria-nav-prev {
  left: 4px;
}

.p-galleria .p-galleria-nav-next {
  right: 4px;
}

.p-galleria .p-galleria-caption {
  position: absolute;
  left: 1px;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  color: #ededed;
  padding: 0.2em 1em;
}

.p-galleria .p-galleria-caption h4 {
  color: #ededed;
}

.p-galleria .p-galleria-panel-content {
  padding: 1em 1.4em;
}

/* Deprecated Grid CSS */
.p-grid {
  clear: both;
  padding: 0;
  margin: 0;
}

.p-grid::before,
.p-grid::after {
  content: '';
  display: table;
}

.p-grid::after {
  clear: both;
}

.p-grid .p-grid-row {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  clear: both;
}

.p-grid-row::after {
  clear: both;
  content: '';
  display: table;
}

.p-grid-col-1,
.p-grid-col-2,
.p-grid-col-3,
.p-grid-col-4,
.p-grid-col-5,
.p-grid-col-6,
.p-grid-col-7,
.p-grid-col-8,
.p-grid-col-9,
.p-grid-col-10,
.p-grid-col-11,
.p-grid-col-12 {
  float: left;
  box-sizing: border-box;
}

.p-grid-col-1 {
  width: 8.33333%;
}

.p-grid-col-2 {
  width: 16.66666%;
}

.p-grid-col-3 {
  width: 25%;
}

.p-grid-col-4 {
  width: 33.33333%;
}

.p-grid-col-5 {
  width: 41.66666%;
}

.p-grid-col-6 {
  width: 50%;
}

.p-grid-col-7 {
  width: 58.33333%;
}

.p-grid-col-8 {
  width: 66.66666%;
}

.p-grid-col-9 {
  width: 75%;
}

.p-grid-col-10 {
  width: 83.33333%;
}

.p-grid-col-11 {
  width: 91.66666%;
}

.p-grid-col-12 {
  width: 100%;
}

@media (min-width: 480px) {
  .p-grid-fixed {
    width: 480px;
  }
}

@media (min-width: 768px) {
  .p-grid-fixed {
    width: 768px;
  }
}

@media (min-width: 960px) {
  .p-grid-fixed {
    width: 960px;
  }
}

@media (min-width: 1024px) {
  .p-grid-fixed {
    width: 1024px;
  }
}

/* Responsive */
@media (max-width: 640px) {
  .p-grid-responsive .p-grid-row {
    display: block;
  }

  .p-grid-responsive .p-grid-col-1,
  .p-grid-responsive .p-grid-col-2,
  .p-grid-responsive .p-grid-col-3,
  .p-grid-responsive .p-grid-col-4,
  .p-grid-responsive .p-grid-col-5,
  .p-grid-responsive .p-grid-col-6,
  .p-grid-responsive .p-grid-col-7,
  .p-grid-responsive .p-grid-col-8,
  .p-grid-responsive .p-grid-col-9,
  .p-grid-responsive .p-grid-col-10,
  .p-grid-responsive .p-grid-col-11,
  .p-grid-responsive .p-grid-col-12 {
    width: 100%;
    float: none;
  }
}

.p-grid.p-grid-pad > .p-grid-row > div {
  padding: 0.25em 0.5em;
}

/* Responsive */
@media (max-width: 640px) {
  .p-grid-responsive .p-grid-row {
    display: block;
  }

  .p-grid-responsive .p-grid-col-1,
  .p-grid-responsive .p-grid-col-2,
  .p-grid-responsive .p-grid-col-3,
  .p-grid-responsive .p-grid-col-4,
  .p-grid-responsive .p-grid-col-5,
  .p-grid-responsive .p-grid-col-6,
  .p-grid-responsive .p-grid-col-7,
  .p-grid-responsive .p-grid-col-8,
  .p-grid-responsive .p-grid-col-9,
  .p-grid-responsive .p-grid-col-10,
  .p-grid-responsive .p-grid-col-11,
  .p-grid-responsive .p-grid-col-12 {
    width: 100%;
    float: none;
  }
}

/* New Grid CSS */
.p-g {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.p-g::after {
  clear: both;
  content: '';
  display: table;
  box-sizing: border-box;
}

.p-g-1,
.p-g-2,
.p-g-3,
.p-g-4,
.p-g-5,
.p-g-6,
.p-g-7,
.p-g-8,
.p-g-9,
.p-g-10,
.p-g-11,
.p-g-12 {
  float: left;
  box-sizing: border-box;
  padding: 0.5em;
}

.p-g-1 {
  width: 8.3333%;
}

.p-g-2 {
  width: 16.6667%;
}

.p-g-3 {
  width: 25%;
}

.p-g-4 {
  width: 33.3333%;
}

.p-g-5 {
  width: 41.6667%;
}

.p-g-6 {
  width: 50%;
}

.p-g-7 {
  width: 58.3333%;
}

.p-g-8 {
  width: 66.6667%;
}

.p-g-9 {
  width: 75%;
}

.p-g-10 {
  width: 83.3333%;
}

.p-g-11 {
  width: 91.6667%;
}

.p-g-12 {
  width: 100%;
}

.p-g-offset-12 {
  margin-left: 100%;
}

.p-g-offset-11 {
  margin-left: 91.66666667%;
}

.p-g-offset-10 {
  margin-left: 83.33333333%;
}

.p-g-offset-9 {
  margin-left: 75%;
}

.p-g-offset-8 {
  margin-left: 66.66666667%;
}

.p-g-offset-7 {
  margin-left: 58.33333333%;
}

.p-g-offset-6 {
  margin-left: 50%;
}

.p-g-offset-5 {
  margin-left: 41.66666667%;
}

.p-g-offset-4 {
  margin-left: 33.33333333%;
}

.p-g-offset-3 {
  margin-left: 25%;
}

.p-g-offset-2 {
  margin-left: 16.66666667%;
}

.p-g-offset-1 {
  margin-left: 8.33333333%;
}

.p-g-offset-0 {
  margin-left: 0%;
}

@media screen and (max-width: 40em) {
  .p-sm-1,
  .p-sm-2,
  .p-sm-3,
  .p-sm-4,
  .p-sm-5,
  .p-sm-6,
  .p-sm-7,
  .p-sm-8,
  .p-sm-9,
  .p-sm-10,
  .p-sm-11,
  .p-sm-12 {
    padding: 0.5em;
  }

  .p-sm-1 {
    width: 8.3333%;
  }

  .p-sm-2 {
    width: 16.6667%;
  }

  .p-sm-3 {
    width: 25%;
  }

  .p-sm-4 {
    width: 33.3333%;
  }

  .p-sm-5 {
    width: 41.6667%;
  }

  .p-sm-6 {
    width: 50%;
  }

  .p-sm-7 {
    width: 58.3333%;
  }

  .p-sm-8 {
    width: 66.6667%;
  }

  .p-sm-9 {
    width: 75%;
  }

  .p-sm-10 {
    width: 83.3333%;
  }

  .p-sm-11 {
    width: 91.6667%;
  }

  .p-sm-12 {
    width: 100%;
  }

  .p-sm-offset-12 {
    margin-left: 100%;
  }

  .p-sm-offset-11 {
    margin-left: 91.66666667%;
  }

  .p-sm-offset-10 {
    margin-left: 83.33333333%;
  }

  .p-sm-offset-9 {
    margin-left: 75%;
  }

  .p-sm-offset-8 {
    margin-left: 66.66666667%;
  }

  .p-sm-offset-7 {
    margin-left: 58.33333333%;
  }

  .p-sm-offset-6 {
    margin-left: 50%;
  }

  .p-sm-offset-5 {
    margin-left: 41.66666667%;
  }

  .p-sm-offset-4 {
    margin-left: 33.33333333%;
  }

  .p-sm-offset-3 {
    margin-left: 25%;
  }

  .p-sm-offset-2 {
    margin-left: 16.66666667%;
  }

  .p-sm-offset-1 {
    margin-left: 8.33333333%;
  }

  .p-sm-offset-0 {
    margin-left: 0%;
  }
}

@media screen and (min-width: 40.063em) {
  .p-md-1,
  .p-md-2,
  .p-md-3,
  .p-md-4,
  .p-md-5,
  .p-md-6,
  .p-md-7,
  .p-md-8,
  .p-md-9,
  .p-md-10,
  .p-md-11,
  .p-md-12 {
    padding: 0.5em;
  }

  .p-md-1 {
    width: 8.3333%;
  }

  .p-md-2 {
    width: 16.6667%;
  }

  .p-md-3 {
    width: 25%;
  }

  .p-md-4 {
    width: 33.3333%;
  }

  .p-md-5 {
    width: 41.6667%;
  }

  .p-md-6 {
    width: 50%;
  }

  .p-md-7 {
    width: 58.3333%;
  }

  .p-md-8 {
    width: 66.6667%;
  }

  .p-md-9 {
    width: 75%;
  }

  .p-md-10 {
    width: 83.3333%;
  }

  .p-md-11 {
    width: 91.6667%;
  }

  .p-md-12 {
    width: 100%;
  }

  .p-md-offset-12 {
    margin-left: 100%;
  }

  .p-md-offset-11 {
    margin-left: 91.66666667%;
  }

  .p-md-offset-10 {
    margin-left: 83.33333333%;
  }

  .p-md-offset-9 {
    margin-left: 75%;
  }

  .p-md-offset-8 {
    margin-left: 66.66666667%;
  }

  .p-md-offset-7 {
    margin-left: 58.33333333%;
  }

  .p-md-offset-6 {
    margin-left: 50%;
  }

  .p-md-offset-5 {
    margin-left: 41.66666667%;
  }

  .p-md-offset-4 {
    margin-left: 33.33333333%;
  }

  .p-md-offset-3 {
    margin-left: 25%;
  }

  .p-md-offset-2 {
    margin-left: 16.66666667%;
  }

  .p-md-offset-1 {
    margin-left: 8.33333333%;
  }

  .p-md-offset-0 {
    margin-left: 0%;
  }
}

@media screen and (min-width: 64.063em) {
  .p-lg-1,
  .p-lg-2,
  .p-lg-3,
  .p-lg-4,
  .p-lg-5,
  .p-lg-6,
  .p-lg-7,
  .p-lg-8,
  .p-lg-9,
  .p-lg-10,
  .p-lg-11,
  .p-lg-12 {
    padding: 0.5em;
  }

  .p-lg-1 {
    width: 8.3333%;
  }

  .p-lg-2 {
    width: 16.6667%;
  }

  .p-lg-3 {
    width: 25%;
  }

  .p-lg-4 {
    width: 33.3333%;
  }

  .p-lg-5 {
    width: 41.6667%;
  }

  .p-lg-6 {
    width: 50%;
  }

  .p-lg-7 {
    width: 58.3333%;
  }

  .p-lg-8 {
    width: 66.6667%;
  }

  .p-lg-9 {
    width: 75%;
  }

  .p-lg-10 {
    width: 83.3333%;
  }

  .p-lg-11 {
    width: 91.6667%;
  }

  .p-lg-12 {
    width: 100%;
  }

  .p-lg-offset-12 {
    margin-left: 100%;
  }

  .p-lg-offset-11 {
    margin-left: 91.66666667%;
  }

  .p-lg-offset-10 {
    margin-left: 83.33333333%;
  }

  .p-lg-offset-9 {
    margin-left: 75%;
  }

  .p-lg-offset-8 {
    margin-left: 66.66666667%;
  }

  .p-lg-offset-7 {
    margin-left: 58.33333333%;
  }

  .p-lg-offset-6 {
    margin-left: 50%;
  }

  .p-lg-offset-5 {
    margin-left: 41.66666667%;
  }

  .p-lg-offset-4 {
    margin-left: 33.33333333%;
  }

  .p-lg-offset-3 {
    margin-left: 25%;
  }

  .p-lg-offset-2 {
    margin-left: 16.66666667%;
  }

  .p-lg-offset-1 {
    margin-left: 8.33333333%;
  }

  .p-lg-offset-0 {
    margin-left: 0%;
  }
}

@media screen and (min-width: 90.063em) {
  .p-xl-1,
  .p-xl-2,
  .p-xl-3,
  .p-xl-4,
  .p-xl-5,
  .p-xl-6,
  .p-xl-7,
  .p-xl-8,
  .p-xl-9,
  .p-xl-10,
  .p-xl-11,
  .p-xl-12 {
    padding: 0.5em;
  }

  .p-xl-1 {
    width: 8.3333%;
  }

  .p-xl-2 {
    width: 16.6667%;
  }

  .p-xl-3 {
    width: 25%;
  }

  .p-xl-4 {
    width: 33.3333%;
  }

  .p-xl-5 {
    width: 41.6667%;
  }

  .p-xl-6 {
    width: 50%;
  }

  .p-xl-7 {
    width: 58.3333%;
  }

  .p-xl-8 {
    width: 66.6667%;
  }

  .p-xl-9 {
    width: 75%;
  }

  .p-xl-10 {
    width: 83.3333%;
  }

  .p-xl-11 {
    width: 91.6667%;
  }

  .p-xl-12 {
    width: 100%;
  }

  .p-xl-offset-12 {
    margin-left: 100%;
  }

  .p-xl-offset-11 {
    margin-left: 91.66666667%;
  }

  .p-xl-offset-10 {
    margin-left: 83.33333333%;
  }

  .p-xl-offset-9 {
    margin-left: 75%;
  }

  .p-xl-offset-8 {
    margin-left: 66.66666667%;
  }

  .p-xl-offset-7 {
    margin-left: 58.33333333%;
  }

  .p-xl-offset-6 {
    margin-left: 50%;
  }

  .p-xl-offset-5 {
    margin-left: 41.66666667%;
  }

  .p-xl-offset-4 {
    margin-left: 33.33333333%;
  }

  .p-xl-offset-3 {
    margin-left: 25%;
  }

  .p-xl-offset-2 {
    margin-left: 16.66666667%;
  }

  .p-xl-offset-1 {
    margin-left: 8.33333333%;
  }

  .p-xl-offset-0 {
    margin-left: 0%;
  }
}

.p-g-nopad {
  padding: 0;
}

.p-inplace .p-inplace-display {
  display: inline;
  cursor: pointer;
  border: 0 none;
  padding: 0.25em;
  font-weight: normal;
}

.p-inplace .p-inplace-content {
  display: inline;
}

.p-fluid .p-inputgroup p-inputmask {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.p-inputgroup p-inputmask:not(:first-child) .p-inputtext {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0 none;
}

.p-inputgroup p-inputmask:not(:last-child) .p-inputtext {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0 none;
}

.p-inputswitch {
  position: relative;
  display: inline-block;
  width: 3em;
  height: 1.75em;
}

.p-inputswitch-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-radius: 30px;
}

.p-inputswitch-slider:before {
  position: absolute;
  content: '';
  height: 1.25em;
  width: 1.25em;
  left: 0.25em;
  bottom: 0.25em;
  border-radius: 50%;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.p-inputswitch-checked .p-inputswitch-slider:before {
  -webkit-transform: translateX(1.25em);
  -ms-transform: translateX(1.25em);
  transform: translateX(1.25em);
}

.p-inputswitch.p-disabled .p-inputswitch-slider,
.p-inputswitch-readonly .p-inputswitch-slider {
  cursor: default;
}

.p-inputtext {
  margin: 0;
  outline: medium none;
  padding: 0.25em;
  font-weight: normal;
}

.p-widget-header .p-inputtext,
.p-widget-content .p-inputtext {
  font-weight: normal;
}

.p-fluid .p-inputtext {
  width: 100%;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.p-inputgroup {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}

.p-inputgroup .p-inputgroup-addon {
  display: inline-block;
  text-align: center;
  min-width: 1.5em;
  padding: 0.25em;
  border-width: 1px;
  border-style: solid;
}

.p-inputgroup .p-inputgroup-addon + .p-inputgroup-addon {
  border-left: 0 none;
}

.p-inputgroup .p-inputtext {
  padding-left: 0.5em;
}

.p-inputgroup .p-inputtext:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0 none;
}

.p-inputgroup .p-inputtext:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0 none;
}

.p-inputgroup .p-button {
  margin-right: 0;
  border-radius: 0;
}

.p-fluid .p-inputgroup .p-button {
  width: auto;
}

.p-fluid .p-inputgroup .p-inputtext {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.p-inputgroup .p-checkbox,
.p-inputgroup .p-radiobutton {
  margin-right: 0;
  vertical-align: bottom;
}

/* Floating Label */
.p-float-label {
  display: block;
  position: relative;
}

.p-float-label > label {
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 0.25em;
  top: 50%;
  margin-top: -0.5em;
  transition: 0.3s ease all;
  -moz-transition: 0.3s ease all;
  -webkit-transition: 0.3s ease all;
  color: #898989;
  line-height: 1;
}

/* breaks firefox so defined separately */
.p-float-label > input:-webkit-autofill ~ label {
  top: -0.75em;
  font-size: var(--font-size-6);
}

.p-float-label > input:focus ~ label,
.p-float-label > input.p-state-filled ~ label,
.p-float-label > .p-inputwrapper-focus ~ label,
.p-float-label > .p-inputwrapper-filled ~ label {
  top: -0.75em;
  font-size: var(--font-size-6);
}

.p-inputtextarea-resizable {
  overflow: hidden;
  resize: none;
}

.p-fluid .p-inputtextarea {
  width: 100%;
}

.p-float-label > textarea ~ label {
  left: 0.25em;
  top: 0.75em;
}

/* breaks firefox so defined separately */
.p-float-label textarea:-webkit-autofill ~ label {
  top: -0.75em;
  font-size: var(--font-size-6);
}

.p-float-label textarea:focus ~ label,
.p-float-label textarea.p-state-filled ~ label {
  top: -0.75em;
  font-size: var(--font-size-6);
}

.p-listbox {
  padding: 0.25em;
  width: 10em;
}

.p-listbox .p-listbox-list-wrapper {
  overflow: auto;
}

.p-listbox .p-listbox-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.p-listbox .p-listbox-item {
  padding: 0.25em;
  border: 0 none;
  cursor: pointer;
  font-weight: normal;
  margin-bottom: 1px;
}

.p-listbox .p-listbox-item > span {
  vertical-align: middle;
}

.p-listbox .p-listbox-item:last-child {
  margin-bottom: 0;
}

.p-listbox.p-disabled .p-listbox-item {
  cursor: default;
}

.p-listbox-header {
  margin-bottom: 0.3em;
  padding: 0.125em 0.2em;
  position: relative;
}

.p-listbox-header .p-checkbox {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.p-listbox-header .p-listbox-filter-container {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  width: 100%;
}

.p-listbox-header.p-listbox-header-w-checkbox .p-listbox-filter-container {
  width: calc(100% - 2em);
}

.p-listbox-header .p-listbox-filter-container .p-listbox-filter-icon {
  position: absolute;
  top: 0.25em;
  left: 0.25em;
}

.p-listbox-header .p-inputtext {
  padding: 0.125em 0.125em 0.125em 1.25em;
  width: 100%;
}

.p-listbox-footer {
  padding: 0.125em 0.2em;
}

.p-lightbox {
  position: fixed;
  display: none;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.p-lightbox-content-wrapper {
  position: relative;
}

.p-lightbox-content {
  position: relative;
  margin: 0;
  padding: 0;
  background-color: var(--color-black);
}

.p-lightbox-nav-right,
.p-lightbox-nav-left {
  position: absolute;
  top: 50%;
  cursor: pointer;
}

.p-lightbox-nav-left {
  left: 0;
}

.p-lightbox-nav-right {
  right: 0;
}

.p-lightbox-caption {
  padding: 0.2em 0.4em;
  display: none;
}

.p-lightbox-caption-text {
  margin: 0.3em 0 0.1em 0;
  float: left;
}

.p-lightbox-close {
  float: right;
  margin: 0;
  padding: 0.125em;
}

.p-lightbox-close.p-state-hover {
  padding: 0;
}

.p-lightbox-nav-left,
.p-lightbox-nav-right {
  opacity: 0.5;
}

.p-lightbox-nav-left:hover,
.p-lightbox-nav-right:hover {
  opacity: 1;
}

.p-megamenu {
  padding: 0.25em;
}

.p-megamenu-root-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.p-megamenu-root-list > .p-menuitem {
  position: relative;
}

.p-megamenu .p-menuitem-link {
  padding: 0.25em;
  display: block;
  text-decoration: none;
}

.p-megamenu .p-menuitem-icon {
  margin-right: 0.25em;
  vertical-align: middle;
}

.p-megamenu .p-menuitem-text {
  vertical-align: middle;
}

.p-megamenu-panel {
  display: none;
  position: absolute;
  width: auto;
}

.p-megamenu-root-list > .p-menuitem-active > .p-megamenu-panel {
  display: block;
}

.p-megamenu-panel .p-menuitem {
  margin: 0.125em 0;
}

.p-megamenu-submenu {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 12.5em;
}

.p-megamenu-submenu-header {
  padding: 0.25em;
}

/* Horizontal */
.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem {
  display: inline-block;
}

.p-megamenu-horizontal
  .p-megamenu-root-list
  > .p-menuitem
  > .p-menuitem-link
  > .p-submenu-icon {
  vertical-align: middle;
  margin-left: 0.25em;
}

/* Vertical */
.p-megamenu-vertical {
  width: 12.5em;
}

.p-megamenu-vertical .p-megamenu-root-list > .p-menuitem {
  display: block;
}

.p-megamenu-vertical .p-megamenu-root-list > .p-menuitem > .p-menuitem-link {
  position: relative;
}

.p-megamenu-vertical
  .p-megamenu-root-list
  > .p-menuitem
  > .p-menuitem-link
  > .p-submenu-icon {
  position: absolute;
  width: 1em;
  height: 1em;
  top: 50%;
  right: 0;
  margin-top: -0.5em;
}

.p-megamenu .p-g {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.p-menu {
  width: 12.5em;
  padding: 0.25em;
}

.p-menu.p-menu-dynamic {
  position: absolute;
}

.p-menu .p-menu-separator {
  border-width: 1px 0 0 0;
}

.p-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.p-menu .p-submenu-header {
  padding: 0.25em 0.5em;
  margin: 0.125em 0;
}

.p-menu .p-menuitem {
  margin: 0.125em 0;
}

.p-menu .p-menuitem-link {
  cursor: pointer;
  padding: 0.25em;
  display: block;
  text-decoration: none;
}

.p-menu .p-menuitem-icon {
  margin-right: 0.25em;
  vertical-align: middle;
}

.p-menu .p-menuitem-text {
  vertical-align: middle;
}

.p-menubar {
  padding: 0.25em;
}

.p-menubar:after {
  content: '';
  clear: both;
  display: table;
}

.p-menubar ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.p-menubar .p-menuitem-link {
  display: block;
  padding: 0.25em;
  position: relative;
  text-decoration: none;
}

.p-menubar .p-menuitem-icon {
  margin-right: 0.25em;
}

.p-menubar .p-menubar-root-list {
  display: inline-block;
}

.p-menubar .p-menubar-root-list > .p-menuitem {
  display: inline-block;
  position: relative;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
  padding: 0.5em;
}

.p-menubar
  .p-menubar-root-list
  > .p-menuitem
  > .p-menuitem-link
  > .p-submenu-icon {
  vertical-align: middle;
  margin-left: 0.25em;
}

.p-menubar .p-menubar-root-list > li ul {
  display: none;
}

.p-menubar .p-menubar-root-list > .p-menu-separator {
  display: inline-block;
  vertical-align: middle;
  height: 1.5em;
}

.p-menubar .p-submenu-list {
  display: none;
  position: absolute;
  min-width: 12.5em;
  padding: 0.25em;
}

.p-menubar .p-submenu-list .p-menuitem {
  margin: 0.125em 0;
  position: relative;
}

.p-menubar .p-menuitem-icon {
  margin-right: 0.25em;
  vertical-align: middle;
}

.p-menubar .p-menuitem-text {
  vertical-align: middle;
}

.p-menubar .p-submenu-list .p-menuitem-link .p-submenu-icon {
  position: absolute;
  margin-top: -0.5em;
  right: 0;
  top: 50%;
}

.p-menubar .p-menuitem-active > .p-submenu > .p-submenu-list {
  display: block;
}

.p-menubar .p-menubar-custom {
  float: right;
  padding: 0.25em;
}

.p-message {
  border: 1px solid;
  margin: 0px 0.25em;
  padding: 0.25em 0.5em;
  display: inline-block;
  vertical-align: top;
}

.p-message .p-message-icon,
.p-message .p-message-text {
  vertical-align: middle;
}

.p-fluid .p-message {
  display: block;
}

.p-messages {
  border: 1px solid;
  margin: 0.5em 0;
  padding: 1em 1em 1em 0.5em;
  position: relative;
  display: flex;
}

.p-messages .p-messages-icon {
  display: inline-block;
  padding: 0;
  vertical-align: middle;
  font-size: 2em;
}

.p-messages .p-messages-summary {
  font-weight: var(--font-weight-semibold);
  margin-left: 0.25em;
}

.p-messages .p-messages-detail {
  margin-left: 0.25em;
}

.p-messages ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  vertical-align: middle;
  display: flex;
  align-items: center;
}

.p-messages ul > li {
  padding: 0.25em;
}

.p-messages.p-messages-noicon ul {
  margin: 0 1.5em 0 0;
}

.p-messages .p-messages-close {
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
}

.p-multiselect {
  display: inline-block;
  position: relative;
  width: auto;
  cursor: pointer;
}

.p-multiselect .p-multiselect-trigger {
  border-right: none;
  border-top: none;
  border-bottom: none;
  cursor: pointer;
  width: 1.5em;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  padding: 0 0.25em;
}

.p-multiselect .p-multiselect-trigger .p-multiselect-trigger-icon {
  top: 50%;
  left: 50%;
  margin-top: -0.3em;
  margin-left: -0.5em;
  position: absolute;
}

.p-multiselect .p-multiselect-label-container {
  overflow: hidden;
}

.p-multiselect .p-multiselect-label {
  display: inline-block;
  padding: 0.25em 2em 0.25em 0.25em;
  width: auto;
  border: none;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
}

.p-multiselect.p-disabled .p-multiselect-trigger,
.p-multiselect.p-disabled .p-multiselect-label {
  cursor: auto;
}

.p-multiselect-panel {
  padding: 0.2em;
  position: absolute;
  min-width: 12em;
}

.p-multiselect .p-multiselect-panel {
  min-width: 100%;
}

.p-multiselect-panel .p-multiselect-items-wrapper {
  overflow: auto;
  position: relative;
  padding: 0.2em 0;
}

.p-multiselect-panel .p-multiselect-list {
  border: 0 none;
}

.p-multiselect-panel .p-multiselect-item {
  border: 0 none;
  cursor: pointer;
  font-weight: normal;
  margin: 1px 0;
  padding: 0.125em 0.25em;
  text-align: left;
  white-space: nowrap;
  display: block;
  position: relative;
}

.p-multiselect-panel .p-multiselect-empty-message {
  padding: 0.5em;
}

.p-multiselect-panel .p-multiselect-item .p-checkbox {
  display: inline-block;
  vertical-align: middle;
}

.p-multiselect-panel .p-multiselect-item span {
  display: inline-block;
  vertical-align: middle;
}

.p-multiselect-header {
  margin-bottom: 0.3em;
  padding: 0.25em;
  position: relative;
  text-align: left;
  min-height: 2em;
}

.p-multiselect-header .p-checkbox {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.p-multiselect-header .p-multiselect-filter-container {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 65%;
}

.p-multiselect-header.p-multiselect-header-no-toggleall
  .p-multiselect-filter-container {
  width: 85%;
}

.p-multiselect-header
  .p-multiselect-filter-container
  .p-multiselect-filter-icon {
  position: absolute;
  top: 0.25em;
  left: 0.125em;
}

.p-multiselect-header .p-inputtext {
  padding: 0.125em 0.125em 0.125em 1.25em;
  width: 100%;
}

.p-multiselect-header .p-multiselect-close {
  position: absolute;
  right: 0.375em;
  top: 0.375em;
  display: block;
  border: 0 none;
  cursor: pointer;
}

.p-multiselect-header a.p-multiselect-all,
.p-multiselect-header a.p-multiselect-none {
  float: left;
  margin-right: 10px;
  display: block;
}

.p-multiselect-footer {
  padding: 0.25em;
}

.p-fluid .p-multiselect {
  width: 100%;
  box-sizing: border-box;
}

.p-organizationchart .p-organizationchart-table {
  border-spacing: 0;
  border-collapse: separate;
  margin: 0 auto;
}

.p-organizationchart .p-organizationchart-table > tr > td {
  text-align: center;
  vertical-align: top;
  padding: 0;
  padding: 0 0.75em;
}

.p-organizationchart .p-organizationchart-node-content {
  padding: 0.5em 0.75em;
  display: inline-block;
  position: relative;
}

.p-organizationchart .p-organizationchart-node-content .p-node-toggler {
  position: absolute;
  bottom: -9px;
  margin-left: -8px;
  z-index: 2;
  left: 50%;
}

.p-organizationchart .p-organizationchart-line-down {
  margin: 0 auto;
  height: 20px;
  width: 1px;
  float: none;
}

.p-organizationchart .p-organizationchart-line-right {
  float: none;
  border-radius: 0px;
}

.p-organizationchart .p-organizationchart-line-left {
  float: none;
  border-radius: 0;
}

.p-organizationchart
  .p-organizationchart-node-content.p-organizationchart-selectable-node {
  cursor: pointer;
}

.p-orderlist {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.p-orderlist-controls-left {
  flex-direction: row;
}

.p-orderlist-controls-right {
  flex-direction: row-reverse;
}

.p-orderlist-controls,
.p-orderlist-list-container {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.p-orderlist-controls {
  padding: 0 0.25em;
  width: 16.66666%;
  align-self: center;
}

.p-orderlist-controls .p-button.p-button-icon-only {
  display: block;
  margin-bottom: 0.25em;
  width: 100%;
}

.p-orderlist-list-container {
  width: 83.33333%;
}

.p-orderlist-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: auto;
  height: 12.5em;
}

.p-orderlist-caption {
  text-align: center;
  padding: 0.5em 0.75em;
  border-bottom: 0 none;
}

.p-orderlist-item {
  margin: 1px;
  padding: 0.125em;
  cursor: pointer;
  border: 0 none;
  font-weight: inherit;
}

.p-orderlist-filter-container {
  position: relative;
  width: 100%;
  padding: 0.5em 0.6em;
  border-bottom: 0 none;
}

.p-orderlist-filter-container .p-inputtext {
  text-indent: 1.1em;
  width: 100%;
}

.p-orderlist-filter-container .p-orderlist-filter-icon {
  position: absolute;
  top: 50%;
  left: 1em;
  margin-top: -0.6em;
}

.p-orderlist.p-disabled .p-orderlist-item,
.p-orderlist.p-disabled .p-button {
  cursor: default;
}

.p-orderlist.p-disabled .p-orderlist-list {
  overflow: hidden;
}

.p-orderlist .p-orderlist-droppoint {
  height: 6px;
  list-style-type: none;
}

@media (max-width: 767px) {
  .p-orderlist-controls {
    width: 100%;
    text-align: center;
  }

  .p-orderlist .p-orderlist-list-container {
    width: 100%;
  }

  .p-orderlist .p-orderlist-controls .p-button.p-button.p-button-icon-only {
    display: inline-block;
    width: 20%;
    margin-right: 0.25em;
  }
}

.p-overlaypanel {
  padding: 0;
  margin: 0;
  position: absolute;
  margin-top: 10px;
}

.p-overlaypanel-flipped {
  margin-top: 0;
  margin-bottom: 10px;
}

.p-overlaypanel-content {
  padding: 0.5em 1em;
}

.p-overlaypanel-close {
  position: absolute;
  top: -1em;
  right: -1em;
  width: 2em;
  height: 2em;
  line-height: 2em;
  text-align: center;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  cursor: pointer;
}

.p-overlaypanel-close-icon {
  line-height: inherit;
}

.p-overlaypanel:after,
.p-overlaypanel:before {
  bottom: 100%;
  left: 1.25em;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.p-overlaypanel:after {
  border-width: 8px;
  margin-left: -8px;
}

.p-overlaypanel:before {
  border-width: 10px;
  margin-left: -10px;
}

.p-overlaypanel-shifted:after,
.p-overlaypanel-shifted:before {
  left: auto;
  right: 1.25em;
  margin-left: auto;
}

.p-overlaypanel-shifted:after {
  margin-right: -8px;
}

.p-overlaypanel:before {
  margin-right: -10px;
}

.p-overlaypanel-flipped:after,
.p-overlaypanel-flipped:before {
  bottom: auto;
  top: 100%;
}

.p-overlaypanel.p-overlaypanel-flipped:after {
  border-bottom-color: transparent;
}

.p-overlaypanel.p-overlaypanel-flipped:before {
  border-bottom-color: transparent;
}

.p-column-filter-overlay {
  background-color: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 6px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  min-width: 12.5rem;

  .p-column-filter-buttonbar {
    justify-content: space-around;
  }
}

p-paginator {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -45px;
  margin: auto;
}

.p-paginator {
  margin: 0;
  text-align: center;
  padding: 0.125em;
  align-items: flex-start !important;
}

.p-paginator .p-paginator-top {
  border-bottom: 0 none;
}

.p-paginator .p-paginator-bottom {
  border-top: 0 none;
}

.p-paginator .p-paginator-left-content {
  float: left;
}

.p-paginator .p-paginator-right-content {
  float: right;
}

.p-paginator .p-paginator-page,
.p-paginator .p-paginator-pages,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last,
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-current {
  display: inline-block;
  min-width: 1.5em;
  height: 1.5em;
  line-height: 1.5em;
  zoom: 1;
  margin-left: 0.063em;
  margin-right: 0.063em;
  text-decoration: none;
  vertical-align: middle;
  text-align: center;
  position: relative;
}

.p-paginator .p-paginator-last,
.p-paginator .p-paginator-first {
  display: none;
}

.p-paginator .p-paginator-pages {
  width: auto;
  line-height: 1;
}

.p-paginator .p-paginator-icon {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  margin-left: -0.5em;
}

.p-paginator .p-paginator-page,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last,
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev {
  cursor: pointer;
}

.p-paginator .p-paginator-page {
  margin: 0 3px;
}

.p-paginator-page,
.p-paginator-next,
.p-paginator-prev {
  min-height: min-content;
}

.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next {
  background-color: var(--color-grey-2);
  border-radius: 0;

  &:hover {
    cursor: pointer;

    ::ng-deep {
      anglelefticon > svg {
        path {
          fill: var(--color-primary);
        }
      }
    }
  }
}

.p-paginator .p-paginator-current,
.p-paginator .p-paginator-rpp-options {
  margin-left: 1em;
  margin-right: 1em;
  background-image: none;
}

.p-paginator .p-paginator-jtp-select option,
.p-paginator .p-paginator-rpp-options option {
  background-image: none;
  border: 0 none;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

.p-paginator a.p-disabled {
  outline: 0 none;
}

.p-paginator .p-dropdown {
  min-width: 4em;
  margin-left: 0.375em;
}

.page-link {
  .visually-hidden {
    display: none;
  }
}

.p-fluid .p-paginator .p-dropdown {
  width: auto;
}

.p-paginator-element {
  color: var(--color-grey-4);
  font-family: var(--font-primary);

  & > span {
    height: 10px;
  }

  &.p-highlight {
    background-color: var(--a-iberia-primary);
    color: white;
    border-radius: 0;
  }
}

.p-panel {
  padding: 0.2em;
}

.p-panel .p-panel-titlebar {
  padding: 0.5em 0.75em;
}

.p-panel .p-panel-titlebar.p-panel-titlebar-clickable {
  cursor: pointer;
}

.p-panel .p-panel-titlebar-icon {
  float: right;
  cursor: pointer;
}

.p-panel .p-panel-titlebar-icon {
  margin-left: 0.2em;
  margin-top: -0.1em;
}

.p-panel .p-panel-content {
  border: 0;
  background: none;
  padding: 0.5em 0.75em;
}

.p-panel .p-panel-footer {
  border-width: 1px 0 0;
  padding: 0.25em 0.5em;
  text-align: left;
}

.p-panel-content-wrapper-overflown {
  overflow: hidden;
}

.p-panelmenu {
  width: auto;
}

.p-panelmenu .p-menu-separator {
  border-width: 1px 0 0 0;
}

.p-panelmenu .p-panelmenu-content-wrapper {
  overflow: hidden;
}

.p-panelmenu .p-panelmenu-header {
  margin: -1px 0 0 0;
  zoom: 1;
}

.p-panelmenu .p-panelmenu-header-link {
  padding: 0.5em;
  display: block;
  text-decoration: none;
}

.p-panelmenu .p-panelmenu-icon {
  vertical-align: middle;
}

.p-panelmenu .p-menuitem-text {
  vertical-align: middle;
}

.p-panelmenu .p-menuitem-icon {
  margin-right: 0.25em;
  vertical-align: middle;
}

.p-panelmenu .p-panelmenu-content {
  padding: 0.25em;
  border-top: 0;
  margin-bottom: 1px;
}

.p-panelmenu .p-submenu-list {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-left: 0;
}

.p-panelmenu
  .p-panelmenu-content
  > .p-panelmenu-root-submenu
  > .p-submenu-list {
  margin-left: 0;
}

.p-panelmenu .p-menuitem {
  overflow: hidden;
  margin: 0.125em 0;
}

.p-panelmenu .p-menuitem-link {
  padding: 0.25em;
  display: block;
  text-decoration: none;
}

.p-password-panel {
  padding: 0.25em 0.5em;
  position: absolute;
}

.p-password-panel .p-password-meter {
  height: 10px;
  padding: 0;
  margin: 0;
}

.p-password-info {
  margin-top: 0.25em;
}

/* Overlay Animations */
.p-password-panel {
  -webkit-transform: translateY(5%);
  -ms-transform: translateY(5%);
  transform: translateY(5%);
  opacity: 0;
  -webkit-transition: transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
}

.p-password-panel-visible {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

.p-password-panel-hidden {
  opacity: 0;
  -webkit-transform: translateY(5%);
  -ms-transform: translateY(5%);
  transform: translateY(5%);
  -webkit-transition: transform 0.3s, opacity 0.15s;
  transition: transform 0.3s, opacity 0.15s;
}

.p-picklist > div {
  float: left;
}

.p-picklist .p-picklist-buttons {
  height: 12.5em;
  padding: 0 0.25em;
}

.p-picklist .p-picklist-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: auto;
  height: 12.5em;
  width: 12.5em;
}

.p-picklist .p-picklist-list li {
  margin: 1px;
  padding: 0.125em;
}

.p-picklist .p-button {
  display: block;
  margin-bottom: 0.25em;
}

.p-picklist .p-button-text-icon-left {
  width: 100%;
}

.p-picklist .p-picklist-item {
  cursor: pointer;
  border: 0 none;
  font-weight: inherit;
}

.p-picklist .p-picklist-caption {
  text-align: center;
  padding: 0.5em 0.75em;
  border-bottom: 0 none;
}

.p-picklist table {
  width: 100%;
  border-collapse: collapse;
}

.p-picklist .p-picklist-filter-container {
  position: relative;
  width: 100%;
  padding: 0.5em 0.6em;
  border-bottom: 0 none;
}

.p-picklist .p-picklist-filter-container .p-picklist-filter {
  text-indent: 1.1em;
  width: 100%;
}

.p-picklist .p-picklist-filter-container .p-picklist-filter-icon {
  position: absolute;
  top: 50%;
  left: 1em;
  margin-top: -0.6em;
}

.p-picklist {
  display: table;
}

.p-picklist > div {
  float: none;
  display: table-cell;
  vertical-align: top;
}

.p-picklist .p-picklist-buttons {
  vertical-align: middle;
}

/* Vertical */
.p-picklist.p-picklist-vertical {
  display: table;
}

.p-picklist.p-picklist-vertical > div {
  float: none;
  display: table-row;
  vertical-align: top;
}

.p-picklist.p-picklist-vertical .p-picklist-buttons {
  text-align: center;
  height: auto;
}

.p-picklist.p-picklist-vertical .p-picklist-buttons .p-button {
  display: inline-block;
}

.p-picklist.p-picklist-vertical .p-button {
  margin-top: 0.25em;
}

.p-picklist-outline {
  outline: 1px dotted black;
  z-index: 1;
}

.p-picklist .p-picklist-droppoint {
  height: 6px;
  list-style-type: none;
}

.p-picklist .p-picklist-list .p-picklist-droppoint-empty {
  height: 100%;
  list-style-type: none;
}

.p-picklist-list.p-picklist-source,
.p-picklist-list.p-picklist-target {
  outline: none;
}

/* Responsive */
.p-picklist.p-picklist-responsive * {
  box-sizing: border-box;
}

.p-picklist.p-picklist-responsive {
  width: 100%;
}

.p-picklist.p-picklist-responsive .p-picklist-listwrapper {
  width: 35%;
}

.p-picklist.p-picklist-responsive
  .p-picklist-listwrapper.p-picklist-listwrapper-nocontrols {
  width: 45%;
}

.p-picklist.p-picklist-responsive .p-picklist-buttons {
  width: 10%;
}

.p-picklist.p-picklist-responsive .p-picklist-buttons button {
  width: 100%;
}

.p-picklist.p-picklist-responsive .p-picklist-list {
  width: auto;
}

/* Responsive */
@media (max-width: 40em) {
  .p-picklist.p-picklist-responsive {
    display: block;
  }

  .p-picklist.p-picklist-responsive > div {
    display: block;
    width: 100% !important;
  }

  .p-picklist.p-picklist-responsive .p-picklist-buttons {
    text-align: center;
    height: auto;
    padding: 0.4em 0;
  }

  .p-picklist.p-picklist-responsive .p-picklist-buttons button {
    display: inline-block;
    width: 20%;
    margin-bottom: 0;
    margin-right: 0.25em;
  }

  .p-picklist.p-picklist-responsive
    .p-picklist-source-controls.p-picklist-buttons {
    padding-bottom: 0.4em;
  }

  .p-picklist.p-picklist-responsive
    .p-picklist-target-controls.p-picklist-buttons {
    padding-top: 0.4em;
  }

  .p-picklist.p-picklist-responsive
    .p-picklist-buttons
    .pi-angle-right::before {
    content: '\e930';
  }

  .p-picklist.p-picklist-responsive
    .p-picklist-buttons
    .pi-angle-double-right::before {
    content: '\e92c';
  }

  .p-picklist.p-picklist-responsive .p-picklist-buttons .pi-angle-left::before {
    content: '\e933';
  }

  .p-picklist.p-picklist-responsive
    .p-picklist-buttons
    .pi-angle-double-left::before {
    content: '\e92f';
  }
}

.p-progressbar {
  height: 1.2em;
  text-align: left;
  position: relative;
  overflow: hidden;
}

.p-progressbar-determinate .p-progressbar-value {
  height: 100%;
  width: 0%;
  position: absolute;
  display: none;
  border: 0 none;
}

.p-progressbar-determinate .p-progressbar-value-animate {
  -webkit-transition: width 1s ease-in-out;
  -moz-transition: width 1s ease-in-out;
  -o-transition: width 1s ease-in-out;
  transition: width 1s ease-in-out;
}

.p-progressbar-determinate .p-progressbar-label {
  text-align: center;
  height: 100%;
  width: 100%;
  position: absolute;
  display: none;
  font-weight: var(--font-weight-semibold);
}

.p-progressbar-indeterminate {
  height: 0.5em;
}

.p-progressbar-indeterminate .p-progressbar-value {
  border: 0 none;
}

.p-progressbar-indeterminate .p-progressbar-value::before {
  content: '';
  position: absolute;
  background-color: var(--color-primary) !important;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  -webkit-animation: p-progressbar-indeterminate-anim 2.1s
    cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
  animation: p-progressbar-indeterminate-anim 2.1s
    cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}

.p-progressbar-indeterminate .p-progressbar-value::after {
  content: '';
  position: absolute;
  background-color: var(--color-primary) !important;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  -webkit-animation: p-progressbar-indeterminate-anim-short 2.1s
    cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
  animation: p-progressbar-indeterminate-anim-short 2.1s
    cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
  -webkit-animation-delay: 1.15s;
  animation-delay: 1.15s;
}

@-webkit-keyframes p-progressbar-indeterminate-anim {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}

@keyframes p-progressbar-indeterminate-anim {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}

@-webkit-keyframes p-progressbar-indeterminate-anim-short {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}

@keyframes p-progressbar-indeterminate-anim-short {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}

.p-progress-spinner {
  position: relative;
  margin: 0 auto;
  width: 100px;
  height: 100px;
  display: inline-block;
}

.p-progress-spinner::before {
  content: '';
  display: block;
  padding-top: 100%;
}

.p-progress-spinner-svg {
  animation: p-progress-spinner-rotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.p-progress-spinner-circle {
  stroke-dasharray: 89, 200;
  stroke-dashoffset: 0;
  stroke: #d62d20;
  animation: p-progress-spinner-dash 1.5s ease-in-out infinite,
    p-progress-spinner-color 6s ease-in-out infinite;
  stroke-linecap: round;
}

@keyframes p-progress-spinner-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes p-progress-spinner-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes p-progress-spinner-color {
  100%,
  0% {
    stroke: #d62d20;
  }
  40% {
    stroke: #0057e7;
  }
  66% {
    stroke: #008744;
  }
  80%,
  90% {
    stroke: #ffa700;
  }
}

.p-radiobutton {
  display: inline-block;
  cursor: pointer;
  vertical-align: middle;
  margin-right: 0.25em;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.p-radiobutton-box {
  width: 1.125em;
  height: 1.125em;
  line-height: 1.125em;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  text-align: center;
  position: relative;
}

.p-radiobutton-icon {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  margin-left: -0.5em;
}

.p-radiobutton {
  vertical-align: middle;
}

.p-rating {
  font-size: 1.25em;
}

.p-scrollpanel-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  float: left;
}

.p-scrollpanel-content {
  height: calc(100% + 18px);
  width: calc(100% + 18px);
  padding: 0 18px 18px 0;
  position: relative;
  overflow: auto;
  box-sizing: border-box;
}

.p-scrollpanel-bar {
  position: relative;
  background: #c1c1c1;
  border-radius: 3px;
  z-index: 2;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.25s linear;
}

.p-scrollpanel-bar-y {
  width: 9px;
  top: 0;
}

.p-scrollpanel-bar-x {
  height: 9px;
  bottom: 0;
}

.p-scrollpanel-hidden {
  visibility: hidden;
}

.p-scrollpanel:hover .p-scrollpanel-bar,
.p-scrollpanel:active .p-scrollpanel-bar {
  opacity: 1;
}

.p-scrollpanel-grabbed {
  -o-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.p-selectbutton {
  display: inline-block;
}

.p-selectbutton.p-state-error {
  padding: 0;
}

.p-selectbutton .p-button.p-state-focus {
  outline: none;
}

.p-sidebar {
  position: fixed;
  padding: 0.5em 1em;
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
}

.p-sidebar-left {
  top: 0;
  left: 0;
  width: 20em;
  height: 100%;
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}

.p-sidebar-right {
  top: 0;
  right: 0;
  width: 20em;
  height: 100%;
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
}

.p-sidebar-top {
  top: 0;
  left: 0;
  width: 100%;
  height: 10em;
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
}

.p-sidebar-bottom {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10em;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
}

.p-sidebar-full {
  width: 100%;
  height: 100%;
  left: 0;
  -webkit-transition: transform 0s;
  transition: transform 0s;
}

.p-sidebar-left.p-sidebar-active,
.p-sidebar-right.p-sidebar-active {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.p-sidebar-left.p-sidebar-sm,
.p-sidebar-right.p-sidebar-sm {
  width: 20em;
}

.p-sidebar-left.p-sidebar-md,
.p-sidebar-right.p-sidebar-md {
  width: 40em;
}

.p-sidebar-left.p-sidebar-lg,
.p-sidebar-right.p-sidebar-lg {
  width: 60em;
}

.p-sidebar-top.p-sidebar-active,
.p-sidebar-bottom.p-sidebar-active {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.p-sidebar-top.p-sidebar-sm,
.p-sidebar-bottom.p-sidebar-sm {
  height: 10em;
}

.p-sidebar-top.p-sidebar-md,
.p-sidebar-bottom.p-sidebar-md {
  height: 20em;
}

.p-sidebar-top.p-sidebar-lg,
.p-sidebar-bottom.p-sidebar-lg {
  height: 30em;
}

.p-sidebar-mask {
  position: fixed;
  width: 100%;
  height: 100%;
}

.p-sidebar-close {
  float: right;
}

.p-sidebar-close {
  cursor: pointer;
}

@media screen and (max-width: 64em) {
  .p-sidebar-left.p-sidebar-lg,
  .p-sidebar-left.p-sidebar-md,
  .p-sidebar-right.p-sidebar-lg,
  .p-sidebar-right.p-sidebar-md {
    width: 20em;
  }
}

.p-slidemenu {
  width: 12.5em;
  padding: 0.25em;
}

.p-slidemenu.p-slidemenu-dynamic {
  position: absolute;
}

.p-slidemenu .p-menu-separator {
  border-width: 1px 0 0 0;
}

.p-slidemenu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.p-slidemenu .p-slidemenu-rootlist {
  position: absolute;
  top: 0;
}

.p-slidemenu .p-submenu-list {
  display: none;
  position: absolute;
  top: 0;
  width: 12.5em;
  padding: 0.25em;
}

.p-slidemenu .p-menuitem-link {
  padding: 0.25em;
  display: block;
  position: relative;
  text-decoration: none;
}

.p-slidemenu .p-menuitem-icon {
  margin-right: 0.25em;
  vertical-align: middle;
}

.p-slidemenu .p-menuitem-text {
  vertical-align: middle;
}

.p-slidemenu .p-menuitem {
  position: relative;
  margin: 0.125em 0;
}

.p-slidemenu .p-menuitem-link .p-submenu-icon {
  position: absolute;
  margin-top: -0.5em;
  right: 0;
  top: 50%;
}

.p-slidemenu .p-slidemenu-wrapper {
  position: relative;
}

.p-slidemenu .p-slidemenu-content {
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}

.p-slidemenu-backward {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0.25em;
  cursor: pointer;
  display: none;
}

.p-slidemenu-backward .p-slidemenu-backward-icon {
  vertical-align: middle;
}

.p-slidemenu-backward span {
  vertical-align: middle;
}

.p-slidemenu .p-menuitem-active {
  position: static;
}

.p-slidemenu .p-menuitem-active > .p-submenu > .p-submenu-list {
  display: block;
}

.p-slidemenu ul:not(.p-active-submenu) > .p-menuitem:not(.p-menuitem-active),
.p-slidemenu
  .p-active-submenu
  > .p-menuitem-active
  > .p-submenu
  > .p-submenu-list {
  display: none;
}

.p-slidemenu .p-active-submenu > .p-menuitem-active ~ .p-menuitem {
  display: block;
}

.p-slider {
  position: relative;
  text-align: left;
}

.p-slider .p-slider-handle {
  position: absolute;
  width: 1.2em;
  height: 1.2em;
  cursor: default;
  -ms-touch-action: none;
  touch-action: none;
  z-index: 1;
}

.p-slider .p-slider-handle.p-slider-handle-active {
  z-index: 2;
}

.p-slider .p-slider-range {
  position: absolute;
  font-size: 0.7em;
  display: block;
  border: 0;
  background-position: 0 0;
}

.p-slider-horizontal {
  height: 0.8em;
}

.p-slider-horizontal .p-slider-handle {
  top: -0.25em;
  margin-left: -0.6em;
}

.p-slider-horizontal .p-slider-range {
  top: 0;
  height: 100%;
}

.p-slider-horizontal .p-slider-range-min {
  left: 0;
}

.p-slider-horizontal .p-slider-range-max {
  right: 0;
}

.p-slider-vertical {
  width: 0.8em;
  height: 100px;
}

.p-slider-vertical .p-slider-handle {
  left: -0.25em;
  margin-left: 0;
  margin-bottom: -0.6em;
}

.p-slider-vertical .p-slider-range {
  left: 0;
  width: 100%;
}

.p-slider-vertical .p-slider-range-min {
  bottom: 0;
}

.p-slider-vertical .p-slider-range-max {
  top: 0;
}

.p-slider-animate .p-slider-handle {
  transition: left 0.3s;
}

.p-spinner {
  display: inline-block;
  overflow: visible;
  padding: 0;
  position: relative;
  vertical-align: middle;
}

.p-spinner-input {
  vertical-align: middle;
  padding-right: 1.5em;
}

.p-spinner-button {
  cursor: default;
  display: block;
  height: 50%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  right: 0;
  text-align: center;
  width: 1.5em;
}

.p-spinner .p-spinner-button-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -0.5em;
  margin-left: -0.5em;
  width: 1em;
}

.p-spinner-up {
  top: 0;
}

.p-spinner-down {
  bottom: 0;
}

/* Fluid */
.p-fluid .p-spinner {
  width: 100%;
}

.p-fluid .p-spinner .p-spinner-input {
  padding-right: 2em;
  width: 100%;
}

.p-fluid .p-spinner .p-spinner-button {
  width: 1.5em;
}

.p-fluid .p-spinner .p-spinner-button .p-spinner-button-icon {
  left: 0.7em;
}

.p-splitbutton {
  position: relative;
  display: inline-block;
  zoom: 1;
}

.p-splitbutton .p-button.p-splitbutton-menubutton {
  width: 2em;
  vertical-align: top;
}

.p-splitbutton.p-disabled button {
  cursor: default;
}

.p-fluid .p-splitbutton {
  width: 100%;
}

.p-fluid .p-splitbutton .p-button:first-child {
  width: calc(100% - 2em);
}

.p-fluid .p-splitbutton .p-button.p-splitbutton-menubutton {
  width: 2em;
}

.p-splitbutton .p-menuitem-link {
  cursor: pointer;
}

.p-steps ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.p-steps .p-steps-item {
  float: left;
  box-sizing: border-box;
  cursor: pointer;
}

.p-steps.p-steps-readonly .p-steps-item {
  cursor: auto;
}

.p-steps .p-steps-item .p-menuitem-link {
  text-decoration: none;
  display: block;
  padding: 1em;
  position: relative;
  text-align: center;
}

.p-steps .p-steps-item.p-highlight .p-menuitem-link,
.p-steps .p-steps-item.p-disabled .p-menuitem-link {
  cursor: default;
}

.p-steps .p-steps-number {
  font-size: 2em;
  display: block;
}

.p-steps .p-steps-title {
  display: block;
  white-space: nowrap;
}

/* Responsive */
@media (max-width: 40em) {
  .p-steps .p-steps-item .p-menuitem-link {
    padding: 0.5em;
  }

  .p-steps .p-steps-item .p-steps-title {
    display: none;
  }
}

.p-table {
  position: relative;
}

.p-table table {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
}

.p-table .p-table-thead > tr > th,
.p-table .p-table-tbody > tr > td,
.p-table .p-table-tfoot > tr > td {
  padding: 0.25em 0.5em;
}

.p-table .p-sortable-column {
  cursor: pointer;
}

.p-table p-sorticon {
  vertical-align: middle;
}

.p-table-auto-layout > .p-table-wrapper {
  overflow-x: auto;
}

.p-table-auto-layout > .p-table-wrapper > table {
  table-layout: auto;
}

/* Sections */
.p-table-caption,
.p-table-summary {
  padding: 0.25em 0.5em;
  text-align: center;
  font-weight: var(--font-weight-semibold);
}

.p-table-caption {
  border-bottom: 0 none;
}

.p-table-summary {
  border-top: 0 none;
}

/* Paginator */
.p-table .p-paginator-top {
  border-bottom: 0 none;
}

.p-table .p-paginator-bottom {
  border-top: 0 none;
}

/* Scrollable */
.p-table-scrollable-wrapper {
  position: relative;
}

.p-table-scrollable-header,
.p-table-scrollable-footer {
  overflow: hidden;
  border: 0 none;
}

.p-table-scrollable-body {
  overflow: auto;
  position: relative;
}

.p-table-virtual-table {
  position: absolute;
}

.p-table-loading-virtual-table {
  display: none;
}

/* Frozen Columns */
.p-table-frozen-view .p-table-scrollable-body {
  overflow: hidden;
}

.p-table-frozen-view
  > .p-table-scrollable-body
  > table
  > .p-table-tbody
  > tr
  > td:last-child {
  border-right: 0 none;
}

.p-table-unfrozen-view {
  position: absolute;
  top: 0px;
}

/* Resizable */
.p-table-resizable > .p-table-wrapper {
  overflow-x: auto;
}

.p-table-resizable .p-table-thead > tr > th,
.p-table-resizable .p-table-tfoot > tr > td,
.p-table-resizable .p-table-tbody > tr > td {
  overflow: hidden;
}

.p-table-resizable .p-resizable-column {
  background-clip: padding-box;
  position: relative;
}

.p-table-resizable-fit .p-resizable-column:last-child .p-column-resizer {
  display: none;
}

.p-table .p-column-resizer {
  display: block;
  position: absolute !important;
  top: 0;
  right: 0;
  margin: 0;
  width: 0.5em;
  height: 100%;
  padding: 0px;
  cursor: col-resize;
  border: 1px solid transparent;
}

.p-table .p-column-resizer-helper {
  width: 1px;
  position: absolute;
  z-index: 10;
  display: none;
}

/* Edit */
.p-table .p-table-tbody > tr > td.p-editing-cell {
  padding: 0;
}

.p-table .p-table-tbody > tr > td.p-editing-cell p-celleditor > * {
  width: 100%;
}

/* Reorder */
.p-table-reorder-indicator-up,
.p-table-reorder-indicator-down {
  position: absolute;
  display: none;
}

/* Responsive */
.p-table-responsive .p-table-tbody > tr > td .p-column-title {
  display: none;
}

@media screen and (max-width: 40em) {
  .p-table-responsive .p-table-thead > tr > th,
  .p-table-responsive .p-table-tfoot > tr > td {
    display: none !important;
  }

  .p-table-responsive colgroup {
    display: none !important;
  }

  .p-table-responsive .p-table-tbody > tr > td {
    text-align: left;
    display: block;
    border: 0 none;
    width: 100% !important;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    float: left;
    clear: left;
  }

  .p-table-responsive .p-table-tbody > tr > td .p-column-title {
    padding: 0.4em;
    min-width: 30%;
    display: inline-block;
    margin: -0.4em 1em -0.4em -0.4em;
    font-weight: var(--font-weight-semibold);
  }
}

/* Loader */
.p-table-loading {
  position: absolute;
  width: 100%;
  height: 100%;
  filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=10)';
  opacity: 0.1;
  z-index: 1;
}

.p-table-loading-content {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 2;
  margin-top: -1em;
  margin-left: -1em;
}

.p-table .p-table-loading-icon {
  font-size: 2em;
}

/** TabMenu **/
.p-tabmenu .p-tabmenu-nav {
  margin: 0;
  padding: 0.25em 0.5em 0 0.25em;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
  list-style: none;
  float: left;
  position: relative;
  margin: 0 0.2em 1px 0;
  padding: 0;
  white-space: nowrap;
  display: block;
  border-bottom: 0;
  top: 1px;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem a {
  float: left;
  padding: 0.5em 1em;
  text-decoration: none;
}

.p-tabmenu .p-tabmenu-nav a {
  padding: 0.5em 1em;
}

.p-tabmenu .p-menuitem-icon {
  margin-right: 0.25em;
  vertical-align: middle;
}

.p-tabmenu .p-menuitem-text {
  vertical-align: middle;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-disabled a {
  cursor: default;
}

.p-tabview {
  padding: 0.25em;
}

.p-tabview .p-tabview-nav {
  margin: 0;
}

.p-tabview .p-tabview-nav li {
  list-style: none;
  float: left;
  position: relative;
  margin: 0 0.125em 1px 0;
  padding: 0;
  white-space: nowrap;
}

.p-tabview .p-tabview-nav li a {
  float: left;
  padding: 0.5em 1em;
  text-decoration: none;
}

.p-tabview .p-tabview-nav li.p-tabview-selected a,
.p-tabview .p-tabview-nav li.p-disabled a,
.p-tabview .p-tabview-nav li.p-state-processing a {
  cursor: text;
}

.p-tabview .p-tabview-nav li a,
.p-tabview.p-tabview-collapsible .p-tabview-nav li.p-tabview-selected a {
  cursor: pointer;
}

.p-tabview .p-tabview-panel {
  border-width: 0;
  padding: 1em;
  background: none;
}

.p-tabview .p-tabview-nav li {
  display: block;
}

.p-tabview .p-tabview-nav li .p-tabview-left-icon {
  margin-right: 0.25em;
  vertical-align: middle;
}

.p-tabview .p-tabview-nav li .p-tabview-right-icon {
  margin-left: 0.25em;
  vertical-align: middle;
}

.p-tabview .p-tabview-title {
  vertical-align: middle;
}

.p-tabview .p-tabview-nav li .p-tabview-close {
  margin: 0.5em 0.3em 0 0;
  cursor: pointer;
}

/* per orientation settings */
/* top and bottom */
.p-tabview.p-tabview-top > .p-tabview-nav li {
  border-bottom: 0;
  top: 1px;
}

.p-tabview.p-tabview-top > .p-tabview-nav {
  padding: 0.2em 0.2em 0;
}

.p-tabview.p-tabview-bottom > .p-tabview-nav {
  padding: 0 0.2em 0.2em;
}

.p-tabview.p-tabview-bottom > .p-tabview-nav li {
  border-top: 0;
}

/* left and right*/
.p-tabview-left::after,
.p-tabview-right::after {
  clear: both;
  content: '.';
  display: block;
  height: 0;
  visibility: hidden;
}

.p-tabview-left > .p-tabview-nav {
  float: left;
  width: 25%;
  height: 300px;
  background-image: none;
  padding-top: 1px;
}

.p-tabview-left > .p-tabview-panels {
  float: right;
  width: 75%;
}

.p-tabview.p-tabview-left > .p-tabview-nav li,
.p-tabview.p-tabview-right > .p-tabview-nav li {
  display: block;
  float: right;
  white-space: normal;
  width: 99%;
}

.p-tabview.p-tabview-left > .p-tabview-nav li {
  margin: 0 0 1px 0;
  border-right: 0 none;
}

.p-tabview.p-tabview-right > .p-tabview-nav {
  float: right;
  width: 25%;
  height: 300px;
  background-image: none;
  padding-top: 1px;
}

.p-tabview.p-tabview-right > .p-tabview-panels {
  float: left;
  width: 75%;
}

.p-tabview.p-tabview-right > .p-tabview-nav li {
  margin: 0 0 1px 0;
  border-left: 0 none;
}

/* RTL */
.p-rtl .p-tabview .p-tabview-nav li {
  float: right;
}

.p-terminal {
  height: 18em;
  overflow: auto;
  padding: 0.25em;
}

.p-terminal-input {
  border: 0 none;
  background-color: transparent;
  color: inherit;
  padding: 0;
  margin: 0 0 0 0.125em;
  width: 75%;
  outline: none;
  vertical-align: baseline;
}

.p-terminal-command {
  margin-left: 0.125em;
  -moz-margin-start: 0.125em;
}

.p-terminal-input::-ms-clear {
  display: none;
}

.p-tieredmenu {
  width: 12.5em;
  padding: 0.25em;
}

.p-tieredmenu.p-tieredmenu-dynamic {
  position: absolute;
}

.p-tieredmenu .p-menu-separator {
  border-width: 1px 0 0 0;
}

.p-tieredmenu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.p-tieredmenu .p-submenu-list {
  display: none;
  position: absolute;
  width: 12.5em;
  padding: 0.25em;
}

.p-tieredmenu .p-menuitem-link {
  padding: 0.25em;
  display: block;
  cursor: pointer;
  position: relative;
  text-decoration: none;
}

.p-tieredmenu .p-menuitem-icon {
  margin-right: 0.25em;
  vertical-align: middle;
}

.p-tieredmenu .p-menuitem-text {
  vertical-align: middle;
}

.p-tieredmenu .p-menuitem {
  position: relative;
  margin: 0.125em 0;
}

.p-tieredmenu .p-menuitem-link .p-submenu-icon {
  position: absolute;
  margin-top: -0.5em;
  right: 0;
  top: 50%;
}

.p-tieredmenu .p-menuitem-active > .p-submenu > .p-submenu-list {
  display: block;
}

.p-toast {
  position: fixed;
  width: 20em;
}

.p-toast .p-toast-message {
  position: relative;
  display: block;
  margin-bottom: 0.75em;
  overflow: hidden;
}

.p-toast .p-toast-message-content {
  padding: 0.5em 1em;
}

.p-toast .p-toast-close-icon {
  position: absolute;
  top: 0.25em;
  right: 0.25em;
  cursor: pointer;
  display: inline-block;
}

.p-toast .p-toast-summary {
  font-weight: var(--font-weight-semibold);
  padding: 0 0 0.5em 0;
  display: block;
}

.p-toast .p-toast-icon {
  position: absolute;
  display: inline-block;
  left: 0.5em;
  top: 0.25em;
  padding: 0;
  font-size: 2em;
}

.p-toast .p-toast-message-text-content {
  padding: 0 0 0.25em 0;
  margin-left: 2.5em;
}

/* Positions */
.p-toast-top-right {
  top: 20px;
  right: 20px;
}

.p-toast-top-left {
  top: 20px;
  left: 20px;
}

.p-toast-bottom-right {
  bottom: 20px;
  right: 20px;
}

.p-toast-bottom-left {
  bottom: 20px;
  left: 20px;
}

.p-toast-top-center {
  top: 20px;
  left: 50%;
  margin-left: -10em;
}

.p-toast-bottom-center {
  bottom: 20px;
  left: 50%;
  margin-left: -10em;
}

.p-toast-center {
  left: 50%;
  top: 50%;
  min-width: 20vw;
  transform: translate(-50%, -50%);
}

.p-toolbar {
  padding: 0.25em 0.5em;
}

.p-toolbar-group-left {
  float: left;
}

.p-toolbar-group-right {
  float: right;
}

.p-tooltip {
  position: absolute;
  display: none;
  padding: 0.25em 0.5em;
  max-width: 12.5em;
}

.p-tooltip.p-tooltip-right,
.p-tooltip.p-tooltip-left {
  padding: 0 0.25em;
}

.p-tooltip.p-tooltip-top,
.p-tooltip.p-tooltip-bottom {
  padding: 0.25em 0;
}

.p-tooltip .p-tooltip-text {
  padding: 0.125em 0.5em;
  background-color: rgb(76, 76, 76);
  color: #ffffff;
  white-space: pre-line;
}

.p-tooltip-arrow {
  scale: 1;
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.p-tooltip-right .p-tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -0.25em;
  border-width: 0.25em 0.25em 0.25em 0;
  border-right-color: rgb(76, 76, 76);
}

.p-tooltip-left .p-tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -0.25em;
  border-width: 0.25em 0 0.25em 0.25em;
  border-left-color: rgb(76, 76, 76);
}

.p-tooltip.p-tooltip-top {
  padding: 0.25em 0;
}

.p-tooltip-top .p-tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -0.25em;
  border-width: 0.25em 0.25em 0;
  border-top-color: rgb(76, 76, 76);
}

.p-tooltip-bottom .p-tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -0.25em;
  border-width: 0 0.25em 0.25em;
  border-bottom-color: rgb(76, 76, 76);
}

.p-tree {
  width: 18em;
}

.p-tree .p-treenode-selectable.p-treenode-content {
  cursor: pointer;
}

.p-tree .p-tree-container {
  height: 100%;
  margin: 0;
  overflow: auto;
  padding: 0.25em;
  white-space: nowrap;
}

.p-tree-empty-message {
  padding: 0.25em;
}

.p-tree .p-treenode-children {
  margin: 0;
  padding: 0 0 0 1em;
}

.p-tree .p-treenode {
  background-attachment: scroll;
  background-color: transparent;
  background-image: none;
  background-position: 0 0;
  background-repeat: repeat-y;
  list-style: none outside none;
  margin: 0;
  padding: 0.125em 0 0 0;
}

.p-tree .p-treenode-droppoint {
  height: 4px;
  list-style-type: none;
}

.p-tree .p-treenode-droppoint-active {
  border: 0 none;
}

.p-tree .p-tree-toggler {
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
}

.p-tree .p-treenode-icon {
  display: inline-block;
  vertical-align: middle;
}

.p-tree .p-treenode-label {
  display: inline-block;
  padding: 0 0.25em;
  vertical-align: middle;
}

.p-tree .p-treenode-label.p-state-hover,
.p-tree .p-treenode-label.p-highlight {
  font-weight: normal;
  border: 0 none;
}

.p-tree .p-treenode.p-treenode-leaf > .p-treenode-content > .p-tree-toggler {
  visibility: hidden;
}

.p-tree .p-checkbox-box {
  cursor: pointer;
}

.p-tree .p-checkbox {
  display: inline-block;
  vertical-align: middle;
}

.p-tree .p-checkbox .p-checkbox-icon {
  margin-left: 1px;
}

.p-tree .p-tree-filter {
  width: 100%;
  box-sizing: border-box;
  padding-right: 1.5em;
}

.p-tree .p-tree-filter-container {
  position: relative;
  margin: 0;
  padding: 0.4em;
  display: inline-block;
  width: 100%;
}

.p-tree .p-tree-filter-container .p-tree-filter-icon {
  position: absolute;
  top: 0.8em;
  right: 1em;
}

/** Fluid **/
.p-fluid .p-tree {
  width: 100%;
}

/** Horizontal Tree **/
.p-tree-horizontal {
  width: auto;
  padding: 0.5em 0;
  overflow: auto;
}

.p-tree.p-tree-horizontal table,
.p-tree.p-tree-horizontal tr,
.p-tree.p-tree-horizontal td {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}

.p-tree.p-tree-horizontal .p-tree-toggler {
  vertical-align: middle;
  margin: 0;
}

.p-tree-horizontal .p-treenode-content {
  font-weight: normal;
  padding: 0.4em 1em 0.4em 0.2em;
}

.p-tree.p-tree-horizontal .p-tree-node-label {
  margin: 0;
}

.p-tree-horizontal .p-treenode-parent .p-treenode-content {
  font-weight: normal;
  white-space: nowrap;
}

.p-tree.p-tree-horizontal .p-treenode {
  padding: 0.25em 2.5em;
}

.p-tree.p-tree-horizontal .p-treenode.p-treenode-leaf,
.p-tree.p-tree-horizontal .p-treenode.p-treenode-collapsed {
  padding-right: 0;
}

.p-tree.p-tree-horizontal .p-treenode-children {
  padding: 0;
  margin: 0;
}

.p-tree.p-tree-horizontal .p-treenode-connector {
  width: 1px;
}

.p-tree.p-tree-horizontal .p-treenode-connector-table {
  height: 100%;
  width: 1px;
}

.p-tree.p-tree-horizontal .p-treenode-connector-line {
  width: 1px;
}

.p-tree.p-tree-horizontal table {
  height: 0;
}

.p-tree.p-tree-horizontal .p-checkbox {
  vertical-align: bottom;
  margin-right: 0.25em;
}

/** Loading **/
.p-tree.p-tree-loading {
  position: relative;
  min-height: 4em;
}

.p-tree .p-tree-loading-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=10)';
  opacity: 0.1;
  z-index: 1;
}

.p-tree .p-tree-loading-content {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 2;
  margin-top: -1em;
  margin-left: -1em;
}

.p-tree .p-tree-loading-content .p-tree-loading-icon {
  font-size: 2em;
}

.p-treetable {
  position: relative;
}

.p-treetable table {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
}

.p-treetable .p-treetable-thead > tr > th,
.p-treetable .p-treetable-tbody > tr > td,
.p-treetable .p-treetable-tfoot > tr > td {
  padding: 0.25em 0.5em;
}

.p-treetable .p-treetable-toggler {
  cursor: pointer;
  display: inline-block;
  height: 1em;
  position: relative;
  top: 50%;
  margin-top: -0.5em;
}

.p-treetable .p-sortable-column {
  cursor: pointer;
}

.p-treetable p-treetablesorticon {
  vertical-align: middle;
}

.p-treetable-auto-layout > .p-treetable-wrapper {
  overflow-x: auto;
}

.p-treetable-auto-layout > .p-treetable-wrapper > table {
  table-layout: auto;
}

.p-treetable .p-treetable-checkbox {
  margin: 0 0.25em;
  vertical-align: middle;
}

/* Sections */
.p-treetable-caption,
.p-treetable-summary {
  padding: 0.25em 0.5em;
  text-align: center;
  font-weight: var(--font-weight-semibold);
}

.p-treetable-caption {
  border-bottom: 0 none;
}

.p-treetable-summary {
  border-top: 0 none;
}

/* Paginator */
.p-treetable .p-paginator-top {
  border-bottom: 0 none;
}

.p-treetable .p-paginator-bottom {
  border-top: 0 none;
}

/* Scrollable */
.p-treetable-scrollable-wrapper {
  position: relative;
}

.p-treetable-scrollable-header,
.p-treetable-scrollable-footer {
  overflow: hidden;
  border: 0 none;
}

.p-treetable-scrollable-body {
  overflow: auto;
  position: relative;
}

.p-treetable-scrollable-body
  > table
  > .p-treetable-tbody
  > tr:first-child
  > td {
  border-top: 0 none;
}

.p-treetable-virtual-table {
  position: absolute;
}

/* Frozen Columns */
.p-treetable-frozen-view .p-treetable-scrollable-body {
  overflow: hidden;
}

.p-treetable-frozen-view
  > .p-treetable-scrollable-body
  > table
  > .p-treetable-tbody
  > tr
  > td:last-child {
  border-right: 0 none;
}

.p-treetable-unfrozen-view {
  position: absolute;
  top: 0px;
}

/* Resizable */
.p-treetable-resizable > .p-treetable-wrapper {
  overflow-x: auto;
}

.p-treetable-resizable .p-treetable-thead > tr > th,
.p-treetable-resizable .p-treetable-tfoot > tr > td,
.p-treetable-resizable .p-treetable-tbody > tr > td {
  overflow: hidden;
}

.p-treetable-resizable .p-resizable-column {
  background-clip: padding-box;
  position: relative;
}

.p-treetable-resizable-fit .p-resizable-column:last-child .p-column-resizer {
  display: none;
}

.p-treetable .p-column-resizer {
  display: block;
  position: absolute !important;
  top: 0;
  right: 0;
  margin: 0;
  width: 0.5em;
  height: 100%;
  padding: 0px;
  cursor: col-resize;
  border: 1px solid transparent;
}

.p-treetable .p-column-resizer-helper {
  width: 1px;
  position: absolute;
  z-index: 10;
  display: none;
}

/* Reorder */
.p-treetable-reorder-indicator-up,
.p-treetable-reorder-indicator-down {
  position: absolute;
  display: none;
}

/* Responsive */
.p-treetable-responsive .p-treetable-tbody > tr > td .p-column-title {
  display: none;
}

@media screen and (max-width: 40em) {
  .p-treetable-responsive .p-treetable-thead > tr > th,
  .p-treetable-responsive .p-treetable-tfoot > tr > td {
    display: none !important;
  }

  .p-treetable-responsive .p-treetable-tbody > tr > td {
    text-align: left;
    display: block;
    border: 0 none;
    width: 100% !important;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    float: left;
    clear: left;
  }

  .p-treetable-responsive .p-treetable-tbody > tr > td .p-column-title {
    padding: 0.4em;
    min-width: 30%;
    display: inline-block;
    margin: -0.4em 1em -0.4em -0.4em;
    font-weight: var(--font-weight-semibold);
  }
}

/* Loader */
.p-treetable-loading {
  position: absolute;
  width: 100%;
  height: 100%;
  filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=10)';
  opacity: 0.1;
  z-index: 1;
}

.p-treetable-loading-content {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 2;
  margin-top: -1em;
  margin-left: -1em;
}

.p-treetable .p-treetable-loading-icon {
  font-size: 2em;
}

.p-virtualscroller .p-virtualscroller-header {
  text-align: center;
  padding: 0.5em 0.75em;
  border-bottom: 0 none;
}

.p-virtualscroller .p-virtualscroller-footer {
  text-align: center;
  padding: 0.25em 0.625em;
  border-top: 0px none;
}

.p-virtualscroller .p-virtualscroller-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

chevronrighticon,
chevrondownicon,
chevronupicon,
chevronlefticon {
  .p-icon {
    width: 12px;
  }
}
