.customSlider {
  .ngx-slider-selection-bar > .ngx-slider-span.ngx-slider-bar {
    background-color: var(--color-primary);
  }
  .ngx-slider-span.ngx-slider-bar {
    background-color: var(--color-grey-4);
  }
  .ngx-slider-span.ngx-slider-pointer.ngx-slider-pointer-min,
  .ngx-slider-span.ngx-slider-pointer.ngx-slider-pointer-max {
    cursor: pointer;
    width: 15px;
    height: 15px;
    top: -6px;
  }
  .ngx-slider .ngx-slider-pointer {
    background-color: #ffffff;
  }
  .ngx-slider-span.ngx-slider-pointer.ngx-slider-pointer-max::after,
  .ngx-slider-span.ngx-slider-pointer.ngx-slider-pointer-min::after {
    content: '';
    width: 15px;
    height: 15px;
    position: absolute;
    border-radius: 50%;
    background-color: var(--color-primary);
    -webkit-mask: url('../../images/icons/circle_arrow.svg') no-repeat 50% 50%;
    mask: url('../../images/icons/circle_arrow.svg') no-repeat 50% 50%;
    -webkit-mask-size: cover;
    mask-size: cover;
    left: 0;
    top: 0;
  }
  .ngx-slider-span.ngx-slider-pointer.ngx-slider-pointer-max::after {
    transform: rotateZ(180deg);
  }
  .ngx-slider-bubble {
    font-family: Source Sans Pro, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-size-4) !important;
    line-height: 13px;
    text-align: center;
    color: #6f6f6f !important;
  }
}
