.pagination {
  display: flex;
  width: fit-content;
  margin: auto;
  margin-right: 0;
  color: #6f6f6f;
  margin-top: 10px;
  .page-link {
    position: relative;
    display: block;
    padding: 1px 7px;
    margin-left: -1px;
    border-radius: 2px;
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-size-12);
    line-height: 20px;
  }
  li {
    margin-right: 7px;
    &:last-child {
      margin-right: 0;
    }
  }
  li.active {
    a {
      background: var(--color-primary);
      color: #ffffff;
    }
  }
  a[aria-label="First"],
  a[aria-label="Last"] {
    display: none;
  }
  a[aria-label="Previous"],
  a[aria-label="Next"] {
    background: #f3f3f3;
    border-radius: 2px;
  }
  .sr-only {
    display: none;
  }
}
