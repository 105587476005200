@import 'ngx-toastr/toastr';
@import './assets/scss/bootstrap-grid.css';

@import './assets/scss/reset.scss';
@import './assets/scss/variables.scss';
@import './assets/scss/tags.scss';
@import './assets/scss/veci.scss';
@import './assets/scss/library/ngx-slider.scss';
@import './assets/scss/library/primeng.scss';
@import './assets/scss/library/flags.scss';
@import './assets/scss/library/primeng-children.scss';
@import './assets/scss/library/ngx-toastr.scss';
@import './assets/scss/library/ngb-pagination.scss';
@import './assets/scss/library/angular-material.scss';

:root {
  --color-grey: var(--color-grey-1);
}

@mixin background-opacity($color, $opacity: 0.3) {
  background: $color; /* The Fallback */
  background: rgba($color, $opacity);
}

body {
  background: var(--color-grey-3);
  min-width: 600px;
  &.no-scroll {
    overflow: hidden;
  }
}

.primary {
  color: var(--color-primary);
}

.form-check {
  display: flex;
  align-items: center;

  > div {
    width: 16px;
    height: 16px;
    background-color: var(--color-grey-2);
    cursor: pointer;

    &.white {
      background-color: var(--color-white);
    }

    &.active {
      background-color: var(--color-primary);
    }
  }

  > label {
    margin-left: 0.5rem;
    color: var(--color-grey-1);
    line-height: 1.125rem;
  }
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.tabOptionsDropdown {
  z-index: 23423423423;
}

.btn-primary-outline,
.btn-void-outline,
.btn-cancel-outline {
  border: 1px solid transparent;
  box-sizing: border-box;
  border-radius: 100px;
  color: var(--color-button);
  font-size: var(--font-size-4);
  min-width: 80px;
  height: 30px;
  transition: all 0.2s ease-in-out;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
  &:hover {
    color: var(--color-white);
    background-color: var(--color-button);
  }
}

.btn-thirds-stroke {
  border: solid 1px var(--color-white);
  color: var(--color-white);
  background-color: var(--color-primary);
  &:hover {
    color: var(--color-primary);
    background-color: var(--color-white);
  }
}

.btn-primary-outline {
  border-color: var(--color-button);
  color: var(--color-button);
  &:hover {
    color: var(--color-white);
    background-color: var(--color-button);
  }
}

.btn-void-outline {
  border-color: var(--color-grey-1);
  color: var(--color-grey-1);
  &:hover {
    color: var(--color-white);
    background-color: var(--color-grey-1);
  }
}

.btn-cancel-outline {
  border-color: #f95f5f;
  color: #f95f5f;
  &:hover {
    color: var(--color-white);
    background-color: #f95f5f;
  }
}

.btn-black-outline {
  border: 1px solid var(--color-black);
  box-sizing: border-box;
  border-radius: 5px;
  color: var(--color-black);
}

.btn-grey-outline {
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  color: #c4c4c4;
}

.button_underline {
  background: none;
  border: none;
  color: var(--color-grey-1); // Cambia este valor para ajustar el color del texto.
  padding: 0;
  font-size: inherit;
  text-decoration: underline;
  cursor: pointer;
}

.btn-primary,
.btn-primary-disabled,
.btn-emit,
.btn-cancel,
.btn-void,
.btn-back,
.btn-primary-alternative,
.btn-primary-alternative-action,
.btn-refund {
  border: none;
  box-shadow: 0 4px 4px #dadada;
  border-radius: 100px;
  min-width: 140px;
  min-height: 38px;
  padding-right: 10px;
  padding-left: 10px;
  background-color: var(--color-white);
  transition: all 0.2s ease-in-out;
}

.btn-primary-alternative-action-width-special {
  border: none;
  box-shadow: 0 4px 4px #dadada;
  border-radius: 100px;
  min-width: 80px;
  min-height: 31px;
  padding-right: 10px;
  padding-left: 10px;
  background-color: var(--color-white);
  transition: all 0.2s ease-in-out;
}

.button-acces-booking {
  border: none;
  min-width: 476px;
  min-height: 50px;
  padding-right: 10px;
  padding-left: 10px;
  transition: all 0.2s ease-in-out;
  color: var(--color-white);
  font-size: 20px;
  border-radius: 4px;
  background: var(--a-iberia-primary, #d7192d);

  &:hover {
    background-color: #9a111f;
  }
}

.btn-primary {
  background-color: var(--color-button);
  color: var(--color-white);
  &:hover {
    background-color: var(--color-hover);
  }
}

button:disabled.btn-primary,
.btn-primary-disabled {
  color: var(--color-white);
  background-color: var(--color-grey-4);
  cursor: not-allowed;
}

.btn-cancel,
.btn-emit,
.btn-void,
.btn-refund,
.btn-back,
.btn-primary-alternative,
.btn-primary-alternative-action,
.btn-primary-alternative-action-width-special {
  position: relative;
  &::after {
    content: '';
    width: 100%;
    height: 100%;
    margin-left: -10px;
    margin-right: -10px;
    border-radius: 100px;
    display: block;
    position: absolute;
    top: 0;
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }
  &:hover::after {
    opacity: 0.04;
  }
}

.btn-cancel {
  color: var(--color-white);
  background-color: var(--color-booking-locator-status-cancel);
  &::after {
    background-color: var(--color-white);
  }
  &:hover::after {
    opacity: 0.1;
  }
}

.btn-emit {
  color: var(--color-white);
  background-color: var(--color-booking-locator-status-emit);
  &::after {
    background-color: var(--color-white);
  }
  &:hover::after {
    opacity: 0.1;
  }
}

.btn-void {
  color: var(--color-white);
  background-color: var(--color-booking-locator-status-void);
  &::after {
    background-color: var(--color-white);
  }
  &:hover::after {
    opacity: 0.1;
  }
}

.btn-refund {
  color: var(--color-white);
  background-color: var(--color-booking-locator-status-refunded);
  &::after {
    background-color: var(--color-white);
  }
  &:hover::after {
    opacity: 0.1;
  }

  &.disabled {
    color: var(--color-white);
    background-color: var(--color-grey-4);
    cursor: not-allowed;
  }
}

.btn-white {
  color: var(--color-grey-1);
  background-color: var(--color-white);
}

.btn-back {
  color: var(--color-grey-1);
  &::after {
    background-color: var(--color-grey-1);
  }
}

.btn-primary-alternative,
.btn-primary-alternative-action-width-special {
  color: var(--color-primary);
  &::after {
    background-color: var(--color-primary);
  }
}
.btn-primary-alternative-action {
  color: var(--color-grey-1);
  &::after {
    background-color: var(--color-primary);
  }
}

.btn-secondary {
  border: 1px solid var(--color-primary);
  background-color: var(--color-white);
  color: var(--color-primary);
}

.btn-alternative {
  border: 1px solid var(--color-black);
  background-color: var(--color-white);
  color: var(--color-black);
}

.color-status {
  &.PV {
    color: var(--color-booking-locator-status-pending-payment);
  }
  &.P {
    color: var(--color-booking-locator-status-pending);
  }
  &.PE {
    color: var(--color-booking-locator-status-pending-payment);
  }
  &.RQ {
    color: var(--color-booking-locator-status-unconfirmed);
  }
  &.E {
    color: var(--color-booking-locator-status-emit);
  }
  &.V {
    color: var(--color-booking-locator-status-void);
  }
  &.C {
    color: var(--color-booking-locator-status-cancel);
  }
  &.RF {
    color: var(--color-booking-locator-status-refunded);
  }
  &.M {
    color: var(--color-booking-locator-status-unconfirmed);
  }
  &.T {
    color: var(--color-booking-locator-status-transferred);
  }
  &.PG {
    color: var(--color-booking-locator-status-pending-payment);
  }
  &.U {
    color: var(color-booking-locator-status-unconfirmed);
  }
}

#modal-loading {
  .header {
    display: none;
  }
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.7);
}

.cdk-overlay-container .mat-dialog-container {
  background: var(--color-white);
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 0;
  position: relative;
  overflow: initial;
  & > div {
    height: fit-content;
    margin-bottom: 0;
    svg {
      display: none;
    }

    button.close {
      position: absolute;
      top: 0;
      right: 0;
      margin: 7px 8px;
      padding: 0;
      height: 0;
      &::before {
        content: url('/assets/images/icons/close.svg');
        width: 17.5px;
        height: 17.5px;
      }
    }
  }
}

.mat-tooltip.listFlight-tooltip {
  & {
    background: #383838;
    font-family: Source Sans Pro, sans-serif;
    font-style: normal;
    font-weight: var(--font-weight-light);
    font-size: var(--font-size-6);
    line-height: 13px;
    color: var(--color-white);
    box-shadow: none;
    position: relative;
    border: 3px solid var(--color-black-2);
    overflow: initial;
  }
  &:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:before {
    border-color: rgba(194, 225, 245, 0);
    border-top-color: var(--color-black-2);
    border-width: 9px;
    margin-left: -9px;
  }
  &.capi {
    text-transform: capitalize;
  }
  &.bottom {
    &:before {
      bottom: 100%;
      left: 50%;
      border: solid transparent;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }
    &:before {
      border-width: 36px;
      margin-left: -36px;
    }
  }
}

.input-calendar-container {
  border: 1px solid transparent;
}

input:focus :not(.error),
.input-calendar-container.open {
  background-color: var(--color-third);
  color: var(--color-primary);
  input {
    color: var(--color-primary);
    background-color: var(--color-third);
  }
  border: 1px solid var(--color-primary);
  svg {
    path {
      fill: var(--color-primary);
    }
  }
}

.cdk-global-scrollblock {
  position: sticky !important;
}

.page-block,
.page-block > div:first-of-type {
  float: none;
  page-break-inside: avoid;
}

@media print {
  @page {
    size: auto;
    margin: 20px !important;
  }
  .print {
    max-width: none;
  }
  body {
    background: var(--color-white) !important;
  }
}

// FROGED
.fg_modal_notify {
  display: none;
}

div#fg_bubble.isVisible {
  display: none !important;

  &.isCallCenter {
    display: block !important;

    &.hideOnVoucher {
      display: none !important;
    }
  }
}

div#fg_modalNot_shadow {
  box-shadow: none !important;
}

iframe#fg_iframe.frogedIframe {
  margin-bottom: 37px;
  display: block !important;
}

iframe#fg_iframe.hideFroged {
  position: absolute !important;
  width: 0 !important;
  height: 0 !important;
  border: 0 !important;
}

div#fg_bubble.isVoucher {
  display: block !important;
  background-color: var(--color-primary) !important;
  background-image: url('/assets/images/icons/close.svg') !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  width: 35px !important;
  height: 35px !important;

  svg {
    display: none;
  }

  &.backButtonContainer {
    display: none !important;
  }
}

.consolidatorHeaderContainer {
  display: flex;
  padding: 40px 50px 78px 50px;
  justify-content: space-between;
  align-items: center;
  background: #e8ebed;

  .title {
    p {
      color: var(--v-2-colores-generales-grey-1, #6f6f6f);
      font-family: Source Sans Pro, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    h1 {
      color: var(--v-2-colores-generales-grey-1, #6f6f6f);
      font-family: Source Sans Pro, sans-serif;
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}

.ngx-toastr {
  &.sportBaggageToast {
    height: 72px !important;
  }
}


.informativeSpecialMessage {
  display: flex;
  justify-content: left;
  align-items: flex-end;
  background: #d4f2ff;
  border-radius: 10px;
  padding: 10px;
  gap: 10px;
  margin-bottom: 20px;

  svg-icon {
    width: 20px;
    height: 20px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  span {
    font-family: Source Sans Pro, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: #6f6f6f;

    &.strong {
      font-weight: 600;
    }
  }
}

.noResultError {
  display: flex;
  align-items: center;
  padding: 4px 12px;
  gap: 21px;

  .svg-icon {
    display: flex;
    width: 55px;
    height: 55px;
    justify-content: center;
    align-items: center;
    border-radius: 230px;
    background: var(--color-grey-9);

    svg-icon {
      width: 35px;
      height: 35px;

      svg > path {
        fill: var(--color-grey-1);
      }
    }
  }

  .text-info {
    display: flex;
    flex-direction: column;
    gap: 17px;
  }
  span {
    color: var(--color-grey-1);
    font-style: normal;
    line-height: normal;
    font-weight: 400;
  }

  .body-text-info {
    display: flex;
    flex-direction: column;

    .body-text-info-title {
      margin-bottom: 0;
      font-size: 16px;
      font-weight: 600;
    }

    .body-text-info-subtitle {
      font-size: 14px;
    }
  }

  .error-reference {
    span {
      font-size: 12px;

      &.error {
        font-weight: 300;
      }
    }
  }
}
