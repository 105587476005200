.toast-container {
  .toast-success,
  .toast-error,
  .toast-warning,
  .toast-info {
    border-radius: 13px;
    top: 60px;
    max-width: 80vw;
    width: 604px !important;
    height: 52px !important;
    padding: 0 !important;
    box-shadow: none;
    &:hover {
      box-shadow: none;
      cursor: default;
    }
    .toast-message {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      padding: 0.5em 1em;
      p {
        font-family: Source Sans Pro, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: var(--font-size-12);
        line-height: 20px;
        padding-left: 10px;
      }
    }
  }
  .toast-error {
    background: var(--color-background-error);
    .toast-message > p {
      color: var(--color-text-error);
    }
  }
  .toast-success {
    background: rgba(174, 255, 201, 0.9);
    .toast-message > p {
      color: #3fb466;
    }
  }
  .toast-warning {
    background: rgba(253, 255, 146, 0.9);
    .toast-message > p {
      color: #bfb12c;
    }
  }
  .toast-info {
    background: rgba(146, 235, 255, 0.9);
    .toast-message > p {
      color: #2c93bf;
    }
  }
}

@media (max-width: 700px) {
  .toast-container {
    position: sticky;
    .toast-success,
    .toast-error,
    .toast-warning,
    .toast-info {
      max-width: 95vw;
      width: 344px;
    }
  }
}
