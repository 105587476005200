:root {
  /** --color-primary: #33cc66;
  --color-primary-less: #AEFFC94D;**/
  --color-lleego: #33cc66;
  --color-white: #ffffff;
  --color-black: #282829;
  --color-black-1: #282829;
  --color-black-2: #383838;

  --color-resident-ko: #f95f5f;
  --color-resident-ok: #33cc66;
  --color-text-error: #f21608;
  --color-background-error: #ffe4e4;
  --color-background-info: #d4f2ff;

  --color-grey-1: #6f6f6f;
  --color-grey-2: #f3f3f3;
  --color-grey-3: #fafafa;
  --color-grey-4: #c4c4c4;
  --color-grey-5: #e5e5e5;
  --color-grey-6: #c5c5c5;
  --color-grey-7: #fbfafa;
  --color-grey-8: #eee;
  --color-grey-9: #eaeced;

  --color-alert-1: #daff8a;
  --color-alert-2: #f3fedd;
  --color-alert-3: #f8ffea;
  --color-alert-4: #ed741c;
  --color-alert-5: #feffbd;
  --color-alert-6: #ffa800;

  --color-rate-ttoo: #64b655;
  --color-rate-etnic: #f87796;
  --color-rate-nego: #c0b020;
  --color-rate-priv: #e0cf34;
  --color-rate-joven: #ffa826;
  --color-rate-neta: #01c9ff;
  --color-rate-pyme: #95123e;
  --color-rate-mar: rgba(0, 38, 255, 0.69);
  --color-rate-public: #13507d;
  --color-rate-disney: rgba(19, 80, 125, 0.69);
  --color-rate-corpfare: #b400ff;
  --color-rate-cupo: #ff07c8;
  --color-rate-student: #ae8a54;
  --color-rate-sport: #33cc66;

  --color-booking-status-emit: #9dffa6;
  --color-booking-status-pending: #ffc062;
  --color-booking-status-cancel: #fba6a6;
  --color-booking-status-refunded: #af7dff;
  --color-booking-status-transferred: #bfbfbf;
  --color-booking-status-void: #535353;
  --color-booking-status-pending-payment: #fdec4f;
  --color-booking-status-unconfirmed: #c2966e;

  --color-booking-locator-status-emit: #33cc66;
  --color-booking-locator-status-pending: #ffa800;
  --color-booking-locator-status-cancel: #f95f5f;
  --color-booking-locator-status-refunded: #af7dff;
  --color-booking-locator-status-transferred: #8d8d8d;
  --color-booking-locator-status-void: #535353;
  --color-booking-locator-status-pending-payment: #cdaa2c;
  --color-booking-locator-status-unconfirmed: #c2966e;

  --a-iberia-primary: #d7192d;

  --color-bg-confirm: #DEF7E7;
  --color-bg-pending: #FFF6C9;
  --color-bg-failed: #FFF3F5;
  --color-text-confirm: #33CC66;
  --color-text-pending: #FFA800;
  --color-text-failed: #F95F5F;

  --font-primary: 'Source Sans Pro', sans-serif;

  --font-size-1: 1.75rem; /* equivalent 28px */
  --font-size-2: 1.5rem; /* equivalent 24px */
  --font-size-3: 1.125rem; /* equivalent 18px */
  --font-size-4: 0.875rem; /* equivalent 14px */
  --font-size-5: 0.625rem; /* equivalent 10px */
  --font-size-6: 0.75rem; /* equivalent 12px */
  --font-size-7: 1.25rem; /* equivalent 20px */

  --font-size-8: 0.5rem; /* equivalent 8px */
  --font-size-9: 0.688rem; /* equivalent 11px */
  --font-size-10: 0.813rem; /* equivalent 13px */
  --font-size-11: 0.938rem; /* equivalent 15px */
  --font-size-12: 1rem; /* equivalent 16px */
  --font-size-13: 1.188rem; /* equivalent 19px */
  --font-size-14: 1.375rem; /* equivalent 22px */
  --font-size-15: 1.438rem; /* equivalent 23px */
  --font-size-16: 1.625rem; /* equivalent 26px */
  --font-size-17: 1.875rem; /* equivalent 30px */
  --font-size-18: 2.5rem; /* equivalent 40px */
  --font-size-19: 2.625rem; /* equivalent 42px */
  --font-size-20: 2.813rem; /* equivalent 45px */

  --font-weight-semibold: 600;
  --font-weight-regular: 400;
  --font-weight-light: 300;
}

/**
 * Convert font-size from px to rem with px fallback
 *
 * @param $size - the value in pixel you want to convert
 *
 * e.g. p {@include fontSize(12px);}
 *
 */

// Function for converting a px based font-size to rem.
@function calculateRem($size) {
  $remSize: $size / 16px;
  //Default font size on html element is 100%, equivalent to 16px;
  @return #{$remSize}rem;
}

// Mixin that will include the fall back px declaration as well as the calculated rem value.
@mixin fontSize($size) {
  font-size: $size;
  font-size: calculateRem($size);
}
